/**
 * API layer object base class
 * For card, board, etc. to do cardInstance.save() etc.
 * needs ._api['create', 'update', 'remove']
 */
class Model {
  constructor(data) {
    Object.assign(this, data);
    this._promise = Promise.resolve();
  }

  save() {
    if (this.id === undefined) {
      this._promise = this._promise.then(() => this._api.create(this)
        .then((obj) => Object.assign(this, obj)));
      return this._promise;
    }
    this._promise = this._promise.then(() => this._api.update(this.id, this)
      .then((obj) => Object.assign(this, obj)));
    return this._promise;
  }

  remove() {
    this.isRemoving = true;
    this._promise = this._promise.then(() => this._api.remove(this.id)); // TODO: this only works in the side panel :(
    return this._promise.then(() => this);
    // return this._api.remove(this.id);
  }
}

export default Model;
