<template>
  <div
    class="dashboard-wrapper"
    @keypress.f4.exact="openSearch"
  >
    <RespondentAvatar
      include-mask
      :include-avatar="false"
      :size="0"
    />
    <print-header />
    <div class="hidden-large-up hamburger-wrapper hide-print">
      <button
        :class="{'toggled': sidebarOpen}"
        class="hamburger-toggle"
        :aria-label="sidebarOpen
          ? $pgettext('ARIA - Mobile menu btn isOpen', 'Stäng navigeringsmeny')
          : $pgettext('ARIA - Mobile menu btn !isOpen', 'Öppna navigeringsmeny')"
        :aria-haspopup="true"
        aria-controls="ariaSidebar"
        @click.stop="toggleSidebar"
      >
        <!-- eslint-disable vue/html-self-closing -->
        <div class="inner">
          <div></div>
          <div></div>
          <div></div>
        </div>
        <!-- eslint-enable vue/html-self-closing -->
        <span
          class="h4"
          v-text="$pgettext('Title — Menu Button', 'Meny')"
        />
      </button>
    </div>
    <dashboard-sidebar
      id="ariaSidebar"
      ref="sidebarEl"
      :toggled="sidebarOpen"
      :aria-hidden="!sidebarOpen"
    />
    <section
      class="dashboard-main"
      :aria-label="$pgettext('ARIA - Main content', 'Huvudinnehåll')"
    >
      <main
        id="main"
        class="main-cols"
      >
        <div
          class="col-main"
          :style="{
            overflow: isInBoard ? 'hidden' : '',
            height: isInNote || isInForms
              ? '100vh' : ''
          }"
          tabindex="-1"
        >
          <router-view v-slot="{ Component }">
            <transition name="fade-in">
              <component :is="Component" />
            </transition>
          </router-view>
          <transition name="fade-in">
            <history-navigation v-show="$route.meta.backHistory && !loading" />
          </transition>
        </div>
        <dashboard-event-pane />
        <print-footer />
      </main>
    </section>
    <baloo-notifications />

    <modalception />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { useBreakpoints } from '@vueuse/core';
import eventBus from 'Utils/eventBus';
import { localStorageIsAvailable } from 'Utils/storage';
import PrintHeader from 'Components/parts/print/PrintHeader';
import PrintFooter from 'Components/parts/print/PrintFooter';
import DashboardSidebar from 'Components/parts/Sidebar';
import DashboardEventPane, { paneEmitter } from 'Components/parts/pane/Pane';
import HistoryNavigation from 'Components/parts/HistoryNavigation';
import BalooNotifications from 'Components/parts/notifications/BalooNotifications';
import Modalception from 'Components/modal/Modalception';
import RespondentAvatar from 'Components/parts/avatars/RespondentAvatar';
import TopBanner from 'Components/parts/TopBanner';
import gettext from '@/gettext';
import { store } from '@/store';

const { $pgettext } = gettext;

const breakpoints = {
  xsmall: 0,
  small: 544,
  medium: 768,
  large: 992,
  xlarge: 1280,
  xxlarge: 1400,
  xxxlarge: 1600,
};

export default {
  components: {
    PrintHeader,
    PrintFooter,
    DashboardSidebar,
    HistoryNavigation,
    BalooNotifications,
    DashboardEventPane,
    Modalception,
    RespondentAvatar,
    TopBanner,
  },

  async beforeRouteEnter(to, from, next) { // ? First mount: Fetch segment depending on situation.
    if (to.name === 'segment') {
      if (!to.params.segmentId) {
        const currSegmentId = localStorageIsAvailable && Number(localStorage.getItem('currentSegmentId')) || null;
        try {
          const me = await store.dispatch('fetchMe');
          if (me?.segments?.length === 0 && me?.customers?.length === 0) {
            throw new Error($pgettext('Error — MainPage', 'Din användare har inte åtkomst till något segment eller företag'));
          }

          const currSegIsAvailableInMe = !!me?.segments?.find((seg) => seg.id === currSegmentId);

          let usedSegmentId = currSegmentId; // ? 1. Use localStorage if available
          if (!currSegIsAvailableInMe) usedSegmentId = me?.segments?.[0]?.id; // ? 2. Use the first listed segment if localStorage id not found in available segments
          const segment = await store.dispatch('fetchSegment', usedSegmentId); // ? 3. Use apiSegment.getFirst if null

          if (segment?.id) {
            to.params.segmentId = Number(segment.id);
            if (localStorageIsAvailable) localStorage.setItem('currentSegmentId', segment.id);
          } else throw new Error($pgettext('Error — MainPage', 'Kunde inte hämta segment från localStorage'));

          next({ name: 'overview', params: to.params });
        } catch (error) {
          store.dispatch('setHiddenParams', { error });
          next({ name: 'login' });
        }
      } else {
        next({ name: 'overview', params: to.params });
      }
    } else next();
    // else if (!to.params.segmentId) next({ ...to, params: { ...to.params, segmentId: localStorageIsAvailable && Number(localStorage.getItem('currentSegmentId')) || null } });
  },

  data() {
    return {
      sidebarOpen: false,
      breakpoints: null,
    };
  },
  computed: {
    ...mapGetters(['loading']),
    largeBreakpoint() { return this.breakpoints.large; },
    isInNote() { return this.$route.name === 'note' && this.largeBreakpoint; },
    isInForms() { return this.$route.name === 'forms'; },
    isInBoard() { return this.$route.name?.indexOf('board') > -1; },
  },
  created() {
    this.breakpoints = useBreakpoints(breakpoints);
  },
  mounted() {
    eventBus.$on('close-sidebar', this.closeSidebar);
    eventBus.$on('create-board', this.openSidebar);
  },
  unmounted() {
    eventBus.$off('close-sidebar', this.closeSidebar);
    eventBus.$off('create-board', this.openSidebar);
  },
  methods: {
    ...mapActions([
      'addCloseHandler',
      'removeCloseHandler',
    ]),
    toggleSidebar() {
      if (this.sidebarOpen) {
        this.closeSidebar();
      } else {
        this.openSidebar();
      }
    },
    openSidebar() {
      paneEmitter.emit('close-pane');
      this.sidebarOpen = true;
      // this.addCloseHandler(this.closeSidebarHandler); // TODO: Should actually have this, but is messed up on mobile
    },
    closeSidebar() {
      this.sidebarOpen = false;
      // this.removeCloseHandler(this.closeSidebarHandler); // TODO: Should actually have this, but is messed up on mobile
    },
    closeSidebarHandler() {
      this.sidebarOpen = false;
    },
    openSearch(evt) {
      // ? Only open SegmentSelect if not focused on an input field
      if (
        document.activeElement.tagName.toUpperCase() !== 'INPUT'
        && document.activeElement.tagName.toUpperCase() !== 'TEXTAREA'
        && !document.activeElement.dataset.input
        && this.$refs.sidebarEl
      ) {
        evt.preventDefault();
        this.$refs.sidebarEl.openDropdown();
      }
    },
  },
};
</script>
