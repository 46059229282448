/* eslint-disable import/order */
import { createApp } from 'vue';
import eventBus from 'Utils/eventBus';
import gettext from '@/gettext';
import { store } from '@/store';
import createRouter from '@/router';
import { setVM } from '@/vm';

import { init as initSentry } from '@/plugins/sentry';
import konami from '@/plugins/konami';
import floatingVue from 'floating-vue';
import 'floating-vue/dist/style.css';

import tcLoader from '@/directives/tc-loader';
import tcBadge from '@/directives/tc-badge';
import tcStickTo from '@/directives/tc-stick-to';
import tcAutoresize from '@/directives/tc-autoresize';
import labelledFieldFocused from '@/directives/labelled-field-focused';
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'; // eslint-disable-line import/extensions

import Card from 'Components/parts/Card';
import TCSelect from 'Components/parts/TCSelect';

import './scss/main.scss';

// ? VM / app
const app = createApp({
  mounted() {
    const minLoadTime = 2000; // ms
    const loader = document.getElementById('appLoader');
    if (window?.tcTimer) {
      const remainder = minLoadTime - (Date.now() - window.tcTimer);
      if (remainder > 0) {
        setTimeout(() => {
          loader.classList.remove('active');
        }, remainder);
        // TODO: [VUE3] get access to router.isReady here and replace the timeout above
        // router.isReady(() => {
        //   setTimeout(() => {
        //     loader.classList.remove('active');
        //   }, 100);
        // });
      } else {
        loader.classList.remove('active');
      }
    } else {
      loader.classList.remove('active');
    }

    konami();
  },
});

// ? Register global directives as { directiveName: directiveOptions }. Will be prefixed with 'v-directiveName'
const globalDirectives = {
  'tc-loader-bar': tcLoader('bar'),
  'tc-loader-pill': tcLoader('pill'),
  'tc-loader-spinner': tcLoader('spinner'),
  'tc-badge': tcBadge,
  'tc-stick-to': tcStickTo,
  'tc-autoresize': tcAutoresize,
  'labelled-field-focused': labelledFieldFocused,
};
Object.entries(globalDirectives).forEach(([name, directiveOptions]) => {
  app.directive(name, directiveOptions);
});

// ? Register global components as { PascalCase: filename }
const globalComponents = {
  Card,
  TcSelect: TCSelect,
};
Object.entries(globalComponents).forEach(([name, component]) => {
  app.component(name, component);
});

// ? A global eventBus that can be used in a any component that needs to pass emits to anywhere.
app.config.globalProperties.$$eventBus = eventBus;
app.config.globalProperties.$store = store; // ? vm.$store = store
app.config.performance = process.env.NODE_ENV === 'development';
app.config.compilerOptions.whitespace = 'preserve'; // 'condense'
app.config.devtools = process.env.NODE_ENV === 'development';

// ? Activate plugins
app.use(gettext); // Needs to be used in router
const router = createRouter(store, app);
// const store = createStore();
app.use(store);
app.use(router);
app.use(autoAnimatePlugin);
app.use(floatingVue, {
  distance: 12,
  arrowPadding: 6,
  overflowPadding: 4,
  themes: {
    tooltip: {
      triggers: ['hover', 'focus', 'touch'], // Default events that trigger the tooltip
      distance: 12,
      container: false,
    },
    select: {
      $extend: 'dropdown',
      triggers: ['click', 'focus', 'touch'],
      placement: 'bottom-start',
      autoHide: false,
      autoSize: true,
      handleResize: true,
      instantMove: true,
      distance: 0,
    },
    'ellipsis-full': {
      $extend: 'select',
      triggers: ['hover', 'focus'],
      popperTriggers: ['hover', 'focus'],
      delay: {
        show: 100,
        hide: 400,
      },
      container: false,
      autoSize: 'min',
      autoHide: true,
      noAutoFocus: true,
      autoBoundaryMaxSize: true,
      distance: -23,
    },
  },
});
router.app = app; // this = vm = router.app
// router.store = store;
setVM(app); // `import { app, vm } from '@/vm';` then reach e.g. `app()?.config.globalProperties.$$eventBus` or `vm()?.$$eventBus`

if (process.env.NODE_ENV !== 'development' && process.env.NODE_ENV !== 'test') {
  initSentry(app, router);
}

// if (process.env.NODE_ENV !== 'production') {
//   document.title = `TC (${process.env.NODE_ENV} v${JSON.parse(unescape(process.env.VUE_APP_VERSION))})`;
// }

// ? Don't set window.intercomSettings here, it autoboots intercom then
if (typeof window !== 'undefined') window.intercomStatus = 0; // 0 = not booted

app.mount('#tcd');

// export default { // ? Unsure if this can be used, probably creates import loop
//   app,
//   store,
//   router,
// };
