/* eslint-disable no-extend-native */
import { h as createElement } from 'vue';
import {
  forOwn, isEmpty, isEqual, pick, pickBy, merge, identity, isPlainObject,
} from 'lodash-es';
import { mapState, mapGetters, mapActions } from 'vuex';
import { requestData, formatFiltersForRequest } from 'API/data';
import { filterMerge } from 'Utils/general';
import { getStepNPSName, getStepNPSContext } from 'Utils/constants';
import { GRAPH_TYPES, getAllowlistCompiledFilter, graphTypeElement } from 'Utils/graph';
import eventBus from 'Utils/eventBus';
import Card from '../Card';
import QueryTable from './QueryResultTable';
import FreeText from './QueryResultFreeText';
import SingleValue from './QueryResultSingleValue';
import BarGraph from './QueryResultBarGraph';
import DonutGraph from './QueryResultDonutGraph';
import QRChart from './QueryResultChart';
import QueryResultHeader from './QueryResultHeader';

const QueryResult = {
  name: 'QueryResult',
  props: {
    card: {
      type: Object,
      required: true,
    },
    board: Object,
    contextFilter: { // i.e. BoardFilter
      type: Object,
      required: false,
    },
    contextBenchmark: { // i.e. BoardBenchmark
      type: Object,
      required: false,
    },
    contextCompare: { // i.e. BoardCompare
      type: [Object, null],
      required: false,
    },
    name: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
    links: Array,
    actions: Array,
    isDraggable: {
      type: Boolean,
      default: false,
    },
    enableOverlay: {
      type: Boolean,
      default: false,
    },
    temporaryCard: {
      type: Boolean,
      default: false,
    },
    isInBoard: {
      type: Boolean,
      default: false,
    },
    previewMode: {
      type: Boolean,
      default: false,
    },
    hydrateProps: null,
  },
  emits: ['update-drag-area', 'loaded', 'toggle', 'update:col-class', 'download-card-png'],
  data() {
    return {
      toqResponse: [],
      toqGlobalResponse: [],
      loading: true,
      question: {},
      hasData: false,
      allNull: false,
      hydratePropsInternal: null,
      abortToken: Math.random().toString(10).substring(2),
      errorMessage: '',
    };
  },
  computed: {
    ...mapState({
      isOverlayed: (state) => state.boards.isOrganizing,
      fetchingTagKeyList: (state) => state.tags.fetchingTagKeyList,
    }),
    ...mapGetters([
      'customerId',
      'segmentId',
      'segmentFilter',
      'segmentTagKeyVal',
    ]),
    GRAPH_TYPE_COMPONENTS() {
      return {
        LineGraph: QRChart,
        BarGraph,
        QueryTable,
        SingleValue,
        FreeText,
        DonutGraph,
      };
    },
    colClass() { return this.card.metadata.colClass || 'col-xs-12'; },
    graphType() { return this.card.metadata.graphType?.selected; },
    compiledCompare() {
      if (this.card.metadata.compare != null) { // ? Doesn’t merge compare values currently
        return { ...this.card.metadata.compare };
      }
      return this.contextCompare;
    },
    compareIsDate() { return this.compiledCompare?.key === undefined && this.compiledCompare?.tag === undefined; },
    omitCompare() { return this.graphType !== GRAPH_TYPES.queryTable; },
    isBarGraph() { return this.graphType === GRAPH_TYPES.barGraph; },
    isDonutGraph() { return this.graphType === GRAPH_TYPES.donutGraph; },
    isTextGraph() { return (this.questionType === 'text' && this.graphType === GRAPH_TYPES.lineGraph); },
    isListLines() { return this.graphType === GRAPH_TYPES.lineGraph && (this.questionType === 'list' || this.questionType === 'listmany'); },
    hideTotals() { return this.compareIsDate === false && this.compiledCompare?.key !== 'customerProxy'; },
    cantUseCompare() { return this.isDonutGraph; },
    filter() {
      return {
        ...this.card.metadata.filter,
        level: this.card.metadata.level,
      };
    },
    compiledFilter() {
      return getAllowlistCompiledFilter({
        filter: filterMerge(this.segmentFilter, this.contextFilter, this.filter),
        graphTypeSelected: this.graphType,
        questionType: this.question?.question_type,
        cardType: 'question',
      });
    },
    compiledBenchmark() {
      return getAllowlistCompiledFilter({
        filter: filterMerge(this.contextBenchmark, this.card.metadata?.benchmark || {}),
        graphTypeSelected: this.graphType,
        questionType: this.question?.question_type,
        cardType: 'question',
      });
    },
    questionId() { return this.card.metadata.question; },
    questionType() { return this.question?.question_type === 'rating' && this.question?.options?.cnps ? 'cnps' : this.question?.question_type; },
    currentFilter() {
      const formattedCompiledFilter = formatFiltersForRequest(this.compiledFilter);
      const segment = this.cantUseCompare || (this.compiledCompare?.key !== 'segment' && this.compiledCompare?.key !== 'customerProxy')
        ? { segment: { segment_id: this.segmentId } } : {};
      const applicantFiltersUsed = pick(formattedCompiledFilter, ['tags', 'answers']);
      const applicantFilter = !isEmpty(applicantFiltersUsed) ? { applicant: applicantFiltersUsed } : {};
      return {
        ...segment,
        ...applicantFilter,
        ...pick(formattedCompiledFilter, ['customer_proxy', 'date', 'segment']),
        question: { question_id: this.questionId },
      };
    },
    labelHeader() {
      if (this.compiledCompare?.key !== undefined) {
        switch (this.compiledCompare.key) {
          case 'customerProxy':
            return this.$pgettext('Table header — customerProxy', 'Proxy');
          default: return this.compiledCompare?.key;
        }
      }
      if (this.compiledCompare?.tag !== undefined) {
        if (!this.segmentTagKeyVal?.[this.compiledCompare.tag]
          && !this.fetchingTagKeyList.has(this.compiledCompare.tag)) {
          this.segmentTagKeyTranslation(this.compiledCompare.tag);
        }
        return this.segmentTagKeyVal?.[this.compiledCompare.tag] || this.compiledCompare.tag;
      }
      if (this.compareIsDate) {
        return this.$gettext('Datum');
      }
      return '';
    },
    currentGroupBy() {
      if (this.isBarGraph || this.isDonutGraph) {
        if (this.questionType === 'text') return ['topic'];
        return ['score'];
      }
      if (this.graphType === 'LineGraph' && this.questionType === 'text') return ['topic', 'date'];
      if (this.omitCompare) return ['date'];
      if (this.compareIsDate) return ['date'];
      if (this.compiledCompare?.tag) { return [`tag:${this.compiledCompare.tag}`]; }
      if (this.compiledCompare?.key) return [this.compiledCompare.key.toLowerCase()];
      return {};
    },
    benchmarkSize() { return this.compiledBenchmark?.size || []; },
    benchmarkSector() { return this.compiledBenchmark?.sector || []; },
    benchmarkLocation() { return this.compiledBenchmark?.location || []; },
    benchmarkIndustry() { return this.compiledBenchmark?.industry || []; },
    hasCardBenchmark() { return Object.values(this.compiledBenchmark).some((benchArray) => !isEmpty(benchArray)); },
    hasGraph() { return this.graphType && graphTypeElement(this.GRAPH_TYPE_COMPONENTS, this.graphType); },
    watchedTrigger() {
      return {
        questionId: this.questionId,
        currentGroupBy: this.currentGroupBy,
        currentFilter: this.currentFilter,
        compiledFilter: this.compiledFilter,
        // compiledCompare: this.compiledCompare,
        compiledBenchmark: this.isBarGraph ? {} : this.compiledBenchmark,
        graphType: this.graphType,
      };
    },
  },
  watch: {
    graphType(newVal, oldVal) {
      if (newVal !== oldVal) {
        // Clear responses to avoid data bleeding when switching graph types
        if (this.toqResponse?.length) this.toqResponse = [];
        if (this.toqGlobalResponse?.length) this.toqGlobalResponse = [];
      }
    },
    card: {
      immediate: true,
      handler(card) {
        this.isRemoving = card.isRemoving;
      },
    },
  },
  mounted() {
    if (this.questionId) {
      this.getQuestionById(this.questionId)
        .then((question) => {
          this.question = question;
          this.load();
          this.createWatchedTrigger();
        })
        .catch((err) => {
          this.question = {};
        });
    }
  },
  beforeUnmount() {
    eventBus.$emit(`abortRequest:${this.abortToken}`);
  },
  methods: {
    ...mapActions(['getQuestionById', 'segmentTagKeyTranslation']),
    createWatchedTrigger() {
      this.$watch('watchedTrigger', async (newVal, oldVal) => {
        await this.$nextTick();
        if (!this.question.question_type) return;
        let load = false;
        let getQuestion = false;
        if (newVal.questionId && oldVal.questionId && newVal.questionId !== oldVal.questionId) {
          getQuestion = true; load = true;
        }

        if (newVal.currentGroupBy && oldVal.currentGroupBy && !isEqual(newVal.currentGroupBy, oldVal.currentGroupBy)) {
          load = true;
        }

        if (newVal.currentFilter && oldVal.currentFilter && !isEqual(newVal.currentFilter, oldVal.currentFilter)) {
          load = true;
        }

        if (newVal.compiledFilter && oldVal.compiledFilter && !isEqual(newVal.compiledFilter, oldVal.compiledFilter)) {
          load = true;
        }

        if (newVal.graphType && oldVal.graphType && !isEqual(newVal.graphType, oldVal.graphType)) {
          load = true;
        }

        // if (newVal.compiledCompare && !isEqual(newVal.compiledCompare, oldVal.compiledCompare)) load = true;
        if (newVal.compiledBenchmark && oldVal.compiledBenchmark
          && !isEqual(newVal.compiledBenchmark, oldVal.compiledBenchmark)) {
          load = true;
        }

        if (getQuestion) this.getQuestionById(this.questionId).then((que) => { this.question = que; });
        if (load) this.load();
      }, { deep: true });
    },
    async toqRequest(queries = [], path = '') {
      return requestData(
        queries,
        path,
        this.abortToken,
        this.$pgettext('Error - Card API data', 'data för card'),
      ) || [];
    },
    populateHydrateProps(resp, globalResp, query, globalQuery) {
      this.hydratePropsInternal = {
        [this.graphType]: {
          toqResponse: resp,
          toqGlobalResponse: globalResp,
          query,
          globalQuery,
        },
      };
    },
    activeFilters(filter) {
      // ? For comparing with board filters, delete level, answers and all props with empty content and check if anything is left.
      const immutableFilter = { ...filter };
      return !isEmpty(forOwn(immutableFilter, (val, key, obj) => {
        if (isEmpty(val) || key === 'level' || key === 'answers') delete obj[key];
      }));
    },
    content() {
      if (this.isRemoving) {
        return [createElement('div', { class: 'p-card' }, [
          createElement('div', { class: 'alert alert-info mb-0' }, this.$gettext('Tar bort...')),
        ])];
      }

      if (this.hasGraph && !!this.card) {
        return [createElement(graphTypeElement(this.GRAPH_TYPE_COMPONENTS, this.graphType), {
          class: 'query-result-content',
          card: this.card,
          board: this.board,
          allNull: this.allNull || null,
          question: this.question,
          loading: this.loading || null,
          hasData: this.hasData || null,
          currentFilter: this.graphType === GRAPH_TYPES.queryTable ? this.currentFilter : null,
          contextFilter: this.contextFilter,
          // contextBenchmark: this.contextBenchmark, // TODO: Enable to let badges be merged?
          // contextCompare: this.contextCompare,
          compiledFilter: this.compiledFilter,
          compiledBenchmark: this.compiledBenchmark,
          compiledCompare: this.compiledCompare,
          disableTabindex: this.isOverlayed || null,
          toqResponse: this.toqResponse,
          toqGlobalResponse: this.toqGlobalResponse,
          errorMessage: this.errorMessage,
          labelHeader: this.labelHeader,
          temporaryCard: this.temporaryCard,
          columnLabels: {
            cnps: getStepNPSName(this.card?.metadata?.level),
            count: this.$pgettext('QueryResult header label', 'Antal'),
            average: this.$gettext('Snitt'),
            cnpsBar: this.$gettext('%{npsShort} fördelning', getStepNPSContext(this.card?.metadata?.level)),
            benchmarks: this.$gettext('Benchmarks'),
          },
          columnTitles: {
            benchmarks: this.$pgettext('Tooltip - Column Header', 'Jämför er med de olika genomsnitten'),
          },
          previewMode: this.previewMode || null,
          onLoading: (state) => {
            this.loading = state;
            if (!state) this.$emit('loaded', !state);
          },
          'onUpdate-drag-area': () => this.$emit('update-drag-area'),
        })];
      }
      return [createElement('div', { class: 'p-card' }, [
        createElement('div', { class: 'alert alert-info mb-0' }, this.$gettext('Det finns ingen mall för denna typ av graf.')),
      ])];
    },
    checkAllNull(resp) {
      return Object.keys(resp?.[0] || {}).length === Object.keys(resp?.[0] || {})
        .filter((v) => resp[0][v] === null).length;
    },
    checkHasContent(resp) {
      return resp?.[0] && Object.keys(pickBy(resp[0]), identity).length > 0 || false;
    },
    load() {
      if (this.graphType === GRAPH_TYPES.freeText) return;
      const query = this.prepareQueries();
      const globalQuery = this.prepareGlobalQueries();

      this.hasData = false;
      const useSavedResponse = isEqual(this.hydrateProps?.[this.graphType]?.query, query)
      && isEqual(this.hydrateProps?.[this.graphType]?.globalQuery, globalQuery)
      && !isEmpty(this.hydrateProps?.[this.graphType]);

      if (useSavedResponse) {
        this.toqResponse = this.hydrateProps[this.graphType]?.toqResponse;
        this.toqGlobalResponse = this.hydrateProps[this.graphType]?.toqGlobalResponse;
        this.allNull = this.checkAllNull(this.toqResponse);
        const hasContent = this.checkHasContent(this.toqResponse);
        if (hasContent || this.allNull) {
          this.hasData = this.isBarGraph || this.isDonutGraph ? hasContent : true;
        } else {
          this.hasData = false;
        }
        this.loading = false;
      }
      if (!this.hasData) {
        this.loading = true;
        this.toqRequest(query)
          .then((response) => {
            // edit globalQueries date filter if segmentFilter.date === {}
            this.allNull = this.checkAllNull(response);
            const hasContent = this.checkHasContent(response);
            if (hasContent || this.allNull) {
              if (this.isBarGraph || this.isDonutGraph) { // ? Doesn’t have global benchmarks setup
                this.toqResponse = response?.reduce((acc, req) => {
                  if (isPlainObject(req)) {
                    acc.push(
                      Object.fromEntries(Object.entries(req).filter(([key, val]) => val?.answer_topic !== null)),
                    ); // ? Remove erroneous nulls from topics
                  } else {
                    acc.push(req);
                  }
                  return acc;
                }, []) ?? [];

                if (this.isDonutGraph) {
                  // Sort the properties of each object in desc order in order to show largest slice "first" in the donut
                  let sortKeys = (o) => Object.entries(o)
                    .sort(([, a], [, b]) => (a.count > b.count ? -1 : 1))
                    .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});

                  this.toqResponse = this.toqResponse.map(sortKeys);
                }

                this.toqGlobalResponse = [];
                this.loading = false;
                this.hasData = hasContent;
                this.$emit('loaded', true);
                if (!this.previewMode) this.populateHydrateProps(response, [], query, globalQuery);
              } else if (globalQuery.length) {
                this.toqRequest(globalQuery, 'global/')
                  .then((globalResponse) => {
                    if (this.compareIsDate) {
                      let { trimResponse, trimGlobalResponse } = this.trimResponses(response, globalResponse);
                      this.toqResponse = trimResponse;
                      this.toqGlobalResponse = trimGlobalResponse;
                    } else {
                      this.toqResponse = response;
                      this.toqGlobalResponse = globalResponse;
                    }
                    if (!this.previewMode) this.populateHydrateProps(response, globalResponse, query, globalQuery);
                    this.loading = false;
                    this.hasData = true;
                    this.$emit('loaded', true);
                  });
              } else {
                if (this.compareIsDate && !['text', 'list', 'listmany'].includes(this.questionType)) {
                  let { trimResponse } = this.trimResponses(response);
                  this.toqResponse = trimResponse;
                } else {
                  this.toqResponse = response;
                }
                if (!this.previewMode) this.populateHydrateProps(response, [], query, globalQuery);
                this.loading = false;
                this.hasData = true;
                this.$emit('loaded', true);
              }
            } else {
              this.loading = false;
              this.hasData = false;
              this.$emit('loaded', true);
            }
          }).catch((err) => {
            this.loading = false;
            this.hasData = false;
            this.$emit('loaded', true);
            this.errorMessage = err?.message;
          });
      }
    },
    trimResponses(response, globalResponse) {
      // !refactor, is this really the best intersection checker?
      const toq0Keys = new Set(response?.[0] ? Object.keys(response[0]) : '');
      const toq1Keys = new Set(response?.[1] ? Object.keys(response[1]) : '');
      const toq2Keys = new Set(response?.[2] ? Object.keys(response[2]) : '');
      const toqGKeys = new Set(globalResponse?.[0] ? Object.keys(globalResponse[0]) : '');
      let intersection = new Set([...toq0Keys].filter((label) => toq1Keys?.has(label)));
      intersection = new Set([...intersection].filter((label) => toq2Keys?.has(label)));
      intersection = new Set([...intersection].filter((label) => toqGKeys?.has(label)));
      intersection = Array.from(intersection);
      return {
        trimResponse: response?.map((val, i) => {
          switch (i) {
            case 0:
            case 1:
            case 2:
              return pick(val, intersection);
            default:
              return val;
          }
        }) || [],
        trimGlobalResponse: globalResponse?.map((val, i) => {
          switch (i) {
            case 0:
              return pick(val, intersection);
            default:
              return val;
          }
        }) || [],
      };
    },

    prepareQueries() {
      const customerProxy = this.compiledCompare?.key === 'segment' || this.compiledCompare?.key === 'customerProxy'
        ? { customer_proxy: { customer_id: this.customerId } }
        : {};
      const filter = this.currentFilter; // ? risk for mutation?
      const groupBy = this.currentGroupBy;
      const benchmarkFilter = { ...filter };
      delete benchmarkFilter.segment;
      delete benchmarkFilter.applicant;
      let benchmarkCompanyAverage = {
        filter: {
          ...benchmarkFilter,
          customer_proxy: {
            customer_id: this.customerId,
          },
        },
      };
      const segmentTotals = {
        filter: {
          ...filter,
          customer_proxy: {
            ...filter.customer_proxy,
            customer_id: this.customerId,
          },
        },
      };

      // ? Return early for specific case isBarGraph ('BarGraph') and isDonutGraph ('DonutGraph')
      if (this.isBarGraph || this.isDonutGraph) {
        const rowCount = { filter: { ...customerProxy, ...filter }, groupBy, hierarchy: 1 };
        const benchmarkCompany = { ...benchmarkCompanyAverage, groupBy, hierarchy: 1 };
        return [rowCount, benchmarkCompany, segmentTotals, benchmarkCompanyAverage];
      }

      // ? Return early for specific case isTextGraph ('text' && 'LineGraph')
      if (this.isTextGraph) {
        const rowCount = {
          filter: { ...customerProxy, ...filter },
          groupBy,
          hierarchy: 2,
        };
        return [rowCount];
      }

      // ? Return early for specific case isListLines ('list'/'listmany' && 'LineGraph')
      if (this.isListLines) {
        const rowCount = {
          filter: { ...customerProxy, ...filter },
          groupBy: ['score', ...groupBy],
          hierarchy: 2,
        };
        return [rowCount];
      }

      // ? Returned for all other cases
      const rowCount = { filter: { ...customerProxy, ...filter }, groupBy: [...groupBy, 'question'], hierarchy: 1 };
      const tableDetails = { filter: { ...customerProxy, ...filter }, groupBy: [...groupBy], hierarchy: 2 };
      if (this.questionType !== 'text') tableDetails.groupBy.push('score');
      // if (this.compiledCompare?.tag) tableDetails.hierarchy = 3;
      const benchmarkCompany = {
        filter: {
          ...benchmarkFilter,
          customer_proxy: {
            customer_id: this.customerId,
          },
        },
        groupBy: [...groupBy],
        hierarchy: 2,
      };
      // if (this.questionType === 'cnps' || this.questionType === 'list' || this.questionType === 'listmany') {
      //   benchmarkCompany.groupBy.push('score');
      // }
      if (this.questionType === 'yesno') {
        benchmarkCompany.groupBy.push('score');
        benchmarkCompanyAverage = merge(benchmarkCompanyAverage, {
          groupby: ['score'],
          hierarchy: 2,
        });
      }
      let totalDistribution = null;
      if (this.questionType === 'cnps' || this.questionType === 'list' || this.questionType === 'listmany') {
        totalDistribution = {
          filter: {
            ...filter,
            customer_proxy: {
              customer_id: this.customerId,
            },
          },
          groupBy: ['score'],
          hierarchy: 1,
        };
      }

      return [rowCount, tableDetails, benchmarkCompany, benchmarkCompanyAverage, segmentTotals, totalDistribution];
    },
    prepareGlobalQueries() {
      if (this.isTextGraph || this.isListLines) return [];

      const filter = { ...this.currentFilter };
      const groupBy = this.currentGroupBy;
      const customBenchmark = this.hasCardBenchmark
        ? {
          customer_proxy: {
            ...(!isEmpty(this.benchmarkSector) && { sector: this.benchmarkSector }),
            ...(!isEmpty(this.benchmarkSize) && { size: this.benchmarkSize }),
            ...(!isEmpty(this.benchmarkLocation) && { country: this.benchmarkLocation }),
            ...(!isEmpty(this.benchmarkIndustry) && { industry: this.benchmarkIndustry }),
          },
        }
        : {};
      const benchmarkFilter = { ...pick(filter, ['date', 'question']), ...customBenchmark };
      const benchmarkGlobal = {
        filter: { ...benchmarkFilter },
        groupBy: [...groupBy],
        hierarchy: 2,
      };
      if (!this.omitCompare && (this.compiledCompare?.key === 'segment' || this.compiledCompare?.key === 'customerProxy' || this.compiledCompare?.tag !== undefined)) {
        delete benchmarkGlobal.groupBy;
      }
      // if (this.questionType === 'yesno') benchmarkGlobal.groupBy.push('score');

      const benchmarkGlobalTotal = {
        filter: { ...benchmarkFilter },
      };
      return [benchmarkGlobal, benchmarkGlobalTotal];
    },
  },
  render() {
    return createElement(
      Card,
      {
        loadingSpinner: this.loading || null,
        isDraggable: this.isDraggable || null,
        isOverlayed: this.isOverlayed || null,
        enableOverlay: this.enableOverlay || null,
        colClass: this.colClass,
        onToggle: (card) => this.$emit('toggle', card),
        'onUpdate:col-class': (colClass) => this.$emit('update:col-class', colClass),
      },
      {
        header: () => createElement(QueryResultHeader, {
          class: 'p-card',
          contextFilter: this.contextFilter,
          contextBenchmark: this.contextBenchmark,
          contextCompare: this.contextCompare,
          compiledFilter: this.compiledFilter,
          compiledBenchmark: this.isBarGraph ? {} : this.compiledBenchmark,
          compiledCompare: this.graphType === GRAPH_TYPES.queryTable ? this.compiledCompare : null,
          name: this.name,
          description: this.description,
          actions: this.actions,
          links: this.links,
          card: this.card,
          board: this.board,
          visible: !this.isOverlayed,
          isInBoard: this.isInBoard,
          previewMode: this.previewMode,
          temporaryCard: this.temporaryCard,
          hydrateProps: this.hydrateProps || this.hydratePropsInternal,
          cardLoading: this.loading,
          'onDownload-card-png': () => this.$emit('download-card-png', this.questionType),
          'onUpdate-drag-area': () => this.$emit('update-drag-area'),
        }),
        body: () => createElement('div', {
          class: !this.hasGraph ? 'query-result query-result-no-graph' : 'query-result',
        }, this.content(createElement)),
      },
    );
  },
};

export default QueryResult;
