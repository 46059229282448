<template>
  <tr
    v-if="showRow"
    ref="qrtr"
    data-testid="clickBtn"
    :class="classes"
    @click.stop="click"
    @mouseover="over"
    @mouseout="out"
  >
    <td>
      <i
        v-if="row.stats.anonymized"
        v-tooltip="{ content: translations.anonymizedTooltip, container: 'body' }"
        class="zmdi zmdi-eye-off subtle-text mr-0.5"
      />
      <i
        v-if="row.stats.error"
        v-tooltip="{ content: translations.erroredTooltip, container: 'body' }"
        class="zmdi zmdi-cloud-off subtle-text mr-0.5"
      />
      <span
        :class="{ 'tc-loading-text': row.loading }"
        v-html="row.label"
      />
    </td>
    <td
      v-for="column in columns"
      :key="column.id"
      :ref="!me.is_staff || (column.value !== 'average' && column.value !== 'cnps') ? null : 'confidenceTooltip'"
      :class="cellClasses(column)"
      @mouseleave="() => destroyTooltip($refs?.confidenceTooltip)"
      @mouseover="!me.is_staff ||
        (column.value !== 'average' && column.value !== 'cnps') ? null :
          createTooltip($refs?.confidenceTooltip, {
            content: () => asyncConfidencePopoverFn(row.label, column.value),
            loadingContent: 'Getting confident...',
            delay: 500,
            placement: 'left',
            container: 'body',
            html: true,
            positioningDisabled: !me.is_staff || (column.value !== 'average' && column.value !== 'cnps'),
          })"
    >
      <cnps-bar
        v-if="column.value === 'cnpsBar'"
        :stats="row.stats.stats"
      />
      <!-- eslint-disable -->
      <table
        v-else-if="column.value === 'benchmarks'"
        class="small-text tc-table tc-table-slim-jim tc-table-centeralign tc-table-pipe tc-table-borderless-outer"
      >
        <tr v-if="benchmarkCols">
          <template v-for="(col, key) in benchmarkCols">
            <template v-if="hydratedBen && hydratedBen[key] && !col" :key="`${col.id}-filler`" />
            <td
              v-else-if="hydratedBen && hydratedBen[key] !== null && typeof hydratedBen[key] === 'object' && col"
              :key="`${col.id}-tuple`"
              :class="benchmarkClasses"
            >
              <template v-for="(tuple, tupkey) in hydratedBen[key]">
                <!-- v-tooltip="{ content: col && `${col.title} [${translateTupkey(tupkey)}]` || '', container: 'body' }" -->
                <span
                  v-if="showTuple(tupkey)"
                  :key="tupkey.id"
                  :ref="`tooltip-${tupkey.id}`"
                  class="cell-tuple"
                  :class="tupleClasses"
                  :title="col && `${col.title} [${translateTupkey(tupkey)}]` || ''"
                  @mouseover="createTooltip(
                    $refs[`tooltip-${tupkey.id}`],
                    { content: col && `${col.title} [${translateTupkey(tupkey)}]` || '', container: 'body' }
                  )"
                  @mouseleave="() => destroyTooltip($refs?.[`tooltip-${tupkey.id}`])"
                >
                  <span>
                    {{ handleTupleOutput(tuple) }}
                  </span>
                </span>
              </template>
            </td>
            <!-- eslint-enable -->
            <td
              v-else-if="hydratedBen && hydratedBen[key] !== null && col"
              :key="`${col.id}-single`"
              :ref="`tooltip-${col.id}`"
              :class="benchmarkClasses"
              :title="col && col.title || ''"
              @mouseover="createTooltip(
                $refs[`tooltip-${col.id}`],
                { content: col && col.title || '', container: 'body' }
              )"
              @mouseleave="() => destroyTooltip($refs?.[`tooltip-${col.id}`])"
            >
              {{ optionalPrefix(hydratedBen[key]) }}
            </td>
            <td
              v-else
              :key="`${col.id}-empty`"
              :class="benchmarkClasses"
              :title="col && col.title || ''"
            >
              –
            </td>
          </template>
        </tr>
      </table>
      <template v-else>
        <strong v-if="!(column.showPercentages && active)">
          {{ stat(column.value, column.showPercentages) }}
        </strong>
        <strong v-if="column.showPercentages && active">{{ stat(column.value) }}</strong>
      </template>
    </td>
  </tr>
</template>

<script>
import { mapGetters } from 'vuex';
import { vOnClickOutside } from '@vueuse/components';
import { translateTerm, prefixNPSValue, hydrateMissingStatsKeys, roundNumber, objectMap } from 'Utils/general';
import { createTooltip, destroyTooltip } from 'Components/parts/widgets/PerformantTooltip';
import CnpsBar from 'Components/parts/graph/CNPSBar';

const NO_VALUE = '–';

export default {
  name: 'QueryResultTableRow',
  components: {
    CnpsBar,
  },
  directives: {
    onClickOutside: vOnClickOutside,
  },
  props: {
    show: Object,
    row: Object,
    benchmark: Object,
    benchmarkCols: Object,
    columns: Array,
    focused: Boolean,
    active: Boolean,
    asyncConfidencePopoverFn: {
      type: Function,
      default: () => () => null,
    },
  },
  emits: ['update-drag-area'],
  computed: {
    ...mapGetters(['me']),
    translations() {
      return {
        anonymizedTooltip: this.$gettext('Kan inte visas pga. anonymiserad data. Krävs mer än 3 kandidatsvar för att visas.'),
        erroredTooltip: this.$gettext('Har inte fått några svar ännu. Men det har skickats ut enkäter.'),
      };
    },
    showRow() {
      const hideErrored = this.row.stats.error && !this.show.rows.erroredRows;
      const hideAnonymized = (this.row.stats.anonymized && typeof this.show.rows === 'undefined')
        || (this.row.stats.anonymized && !this.show.rows.anonymizedRows);

      if (hideErrored || hideAnonymized) {
        this.$emit('update-drag-area');
        return false;
      }
      return true;
    },
    classes() {
      return {
        active: this.active,
        loading: this.row.loading,
        'active-focus': this.focused,
      };
    },
    benchmarkClasses() {
      if (!this.hydratedBen) return 'col-xs-6';
      switch (Math.min(
        Object.keys(this.benchmarkCols).length,
        Object.keys(this.hydratedBen).length,
      )) {
        case 2: return 'col-xs-6';
        case 3: return 'col-xs-4';
        case 4: return 'col-xs-3';
        default: return 'col-xs-6';
      }
    },
    tupleClasses() {
      if (!this.hydratedBen) return 'col-xs-12';
      let tuples = Object.keys(Object.values(this.hydratedBen)[1])
        .reduce((acc, key) => (key && this.showTuple(key) ? ++acc : acc), 0);
      switch (tuples) {
        case 1: return 'col-xs-12';
        case 2: return 'col-xs-6';
        case 3: return 'col-xs-4';
        case 4: return 'col-xs-3';
        default: return 'col-xs-12';
      }
    },
    hydratedBen() {
      // ? For filling out keys that are not received from request by presuming their value
      if (!this.benchmark) return {};
      return objectMap(this.benchmark, (ben) => {
        if (ben !== null && typeof ben === 'object') return hydrateMissingStatsKeys(ben);
        return ben;
      });
    },
  },
  methods: {
    createTooltip,
    destroyTooltip,
    optionalPrefix(val) {
      if (val === undefined || val === NO_VALUE) return val;
      // ? Just saw that if one disables "show cnps" it doesn’t prefix the value. Don’t know why, but afraid of changing it.
      return this.show?.columns.cnps ? prefixNPSValue(roundNumber(val)) : val.toFixed(1);
    },
    handleTupleOutput(tuple) {
      return tuple !== null ? `${roundNumber(tuple)}%` : NO_VALUE;
    },
    translateTupkey(tupkey) { return translateTerm(tupkey); },
    click() { this.$$eventBus.$emit('update:focus-row', this.row); },
    over() { this.$$eventBus.$emit('update:hover-row', this.row); },
    out() { this.$$eventBus.$emit('update:hover-row'); },
    showTuple(tupkey) {
      return this.columns.find(({ value }) => tupkey === value); // show only visible cols
    },
    cellClasses(column) {
      return {
        'tc-cnps-cell': column.value === 'cnps',
        'tc-cnps-bar-cell': column.value === 'cnpsBar',
        'tc-benchmarks-cell': column.value === 'benchmarks',
        'tc-table-number-cell': column.numberCell,
      };
    },
    stat(key, percentages = false, from = null, row = this.row) {
      const location = from === null
        ? row.stats.displayValues
        : row.stats[from]?.displayValues;
      if (location) {
        if (percentages) {
          if (location.percentages[key]) {
            return location.percentages[key];
          }
        }
        if (location[key] !== undefined && location[key] !== null && !Number.isNaN(location[key])) {
          return location[key];
        }
      }
      return NO_VALUE;
    },
  },
};
</script>
