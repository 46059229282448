import { isObject, isArray, isPlainObject, isEmpty } from 'lodash-es';
import Model from 'API/model';
// import { format } from 'Utils/dateHelpers';
import { GRAPH_TYPES, KEY_METRIC_GRAPH_TYPES, GOAL_GRAPH_TYPES, GRAPH_TYPES_SETTINGS } from 'Utils/graph';
import {
  // putUrl,
  postUrl,
  patchUrl,
  deleteUrl,
  getBaseUrl,
} from 'Utils/api';
import { handleAuthError } from 'Utils/authHelpers';
import { store } from '@/store';
import gettext from '@/gettext';

const { $pgettext } = gettext;

/**
 ** Type Definitions
 * @typedef {object} IorderAsCard
 * @property {string} IorderAsCard.prevCardOrder
 * @property {string} IorderAsCard.nextCardOrder
 * @example { prevCardOrder = 't', nextCardOrder = 'z' }
 */

export function getCompareKey(compare) {
  return 'tag' in compare ? compare.tag : compare.key;
}

export function returnGraphTypeObject(meta = {}, graphType = null) {
  if (graphType?.selected && graphType?.settings) return graphType; // ? Short-circuit if passed via graphType

  const gt = graphType || meta?.graphType || GRAPH_TYPES.queryTable;
  // const specificSettings = { // Now more unused as we moved away from categoryOption & subCategoryOption
  //   ...((meta?.filter?.level || meta?.level) && { level: meta?.filter?.level || meta.level }),
  // };
  return {
    selected: gt,
    settings: {
      // ...(!isEmpty(specificSettings) && GRAPH_TYPES_SETTINGS()[gt] && {
      //   [gt]: GRAPH_TYPES_SETTINGS(specificSettings)[gt],
      // }),
    },
  };
}

function retroactivelyAddedCardMetadata(meta) {
  // ? If a card property isn't in customers card.metadata, it will be added retroactively (aka. FE Migrations)
  // ? One beautiful day, we will remove this function in a combo effort with BE.
  // ? And replace some very specific cases with graphType.settings instead of show object.
  if (!meta) return {};
  let r = { ...meta };
  if (r.show) {
    if (r.show.columns?.benchmarks === undefined) { // ! Retroactively added 30 nov 2019
      r.show.columns = {
        ...r.show.columns,
        benchmarks: true,
      };
    }
    if (r.show.rows === undefined) { // ! Retroactively added 5 dec 2019
      r.show = {
        ...r.show,
        rows: {
          anonymizedRows: false,
          erroredRows: false,
        },
      };
    }
    if (r.show.graph === undefined) { // ! Retroactively added 18 mar 2020
      r.show = {
        ...r.show,
        graph: {
          series: [],
        },
      };
    }
    if (r.show.columns?.expandedHeader === undefined) { // ! Retroactively added 20 sep 2022
      r.show.columns = {
        ...r.show.columns,
        expandedHeader: false,
      };
    }
    if (r.show.sort === undefined) { // ! Retroactively added 29 nov 2023
      const orderAsc = r.graphType?.selected === KEY_METRIC_GRAPH_TYPES.ResponseRate;
      r.show = {
        ...r.show,
        sort: { orderAsc },
      };
    }
    if (r.show.question === undefined && r.graphType?.selected === KEY_METRIC_GRAPH_TYPES.RankingGauge) { // ! Retroactively added 29 jan 2024
      r.show = {
        ...r.show,
        question: [],
      };
    }
    if (r.show.onlyBenchmarkableQuestions === undefined && r.graphType?.selected === KEY_METRIC_GRAPH_TYPES.SWRanking) { // ! Retroactively added 2 feb 2024
      r.show = {
        ...r.show,
        onlyBenchmarkableQuestions: false,
      };
    }
  }
  if (r.filter?.language) { // ! Retroactively fixed 29 jul 2024
    delete r.filter.language; // ? Delete key if exists
  }
  if (r.filter?.level || r.filter?.level === '') { // ! Retroactively fixed 15 jan 2021
    if (!r.level) r.level = r.filter.level || ''; // ? Sets if available
    delete r.filter.level; // ? Deletes key if not
  }
  if (typeof r.graphType === 'string') { // ! Retroactively changed 18 oct 2021
    r.graphType = returnGraphTypeObject(r);
  }
  if (r.graphType?.selected === KEY_METRIC_GRAPH_TYPES.ResponseRate) { // ! Retroactively added 21 nov 2023
    const gtRR = KEY_METRIC_GRAPH_TYPES.ResponseRate;
    r.graphType.settings = {
      ...r.graphType.settings,
      [gtRR]: {
        ...r.graphType.settings?.[gtRR],
        ...GRAPH_TYPES_SETTINGS({ type: r.graphType.settings?.[gtRR]?.type })[gtRR],
      },
    };
  }
  if (r.filter?.categoryOption !== undefined) { // ! Retroactively fixed 22 oct 2021
    delete r.filter.categoryOption;
  }
  if (r.filter?.subCategoryOption !== undefined) { // ! Retroactively fixed 22 oct 2021
    delete r.filter.subCategoryOption;
  }
  // ? isObject is true for arrays and objects
  if (isObject(r.level)) { // ! Retroactively changed 20 oct 2021
    if (isArray(r.level)) r.level = r.level?.[0].value;
    if (isPlainObject(r.level)) r.level = r.level.value;
  }
  return r;
}

export const DEFAULT_CARD_METADATA = {
  level: '',
  filter: {},
  benchmark: {},
  compare: null,
  show: {
    pageSize: 10,
    numberCell: true,
    percentages: false,
    graph: {
      series: [],
    },
    rows: {
      anonymizedRows: false,
      erroredRows: false,
    },
    columns: {
      cnps: false,
      cnpsBar: false,
      baseValues: [],
      average: true,
      count: true,
      benchmarks: true,
      expandedHeader: false,
    },
  },
  goal: {
    // endDate: 'yyyy-MM-dd', // Future use // Perhaps we add this in a later stage
    endVal: null, // ? What is the end goal ?
    // startDate: format(new Date(), 'yyyy-MM-dd'), // Future use // ? From when does this goal start ?
    unit: 'cnpsValue', // 'cnpsValue' | 'promoter' // later also 'passive' | 'detractor' // ? What is the goal measuring ?
    // reverseStats: false, // Future use, when we implement 'detractor' // ? Should it be considered negative/positive ?
  },
  question: null,
  graphType: {
    selected: GRAPH_TYPES.queryTable,
    settings: {},
  },
  description: '',
  name: '',
  order: '',
};

class Card extends Model {
  constructor(data) {
    super(data);
    this.section = this.section ?? null;
    this.created_at = this.created_at ?? new Date(); // utc or not?
    this.metadata = { // ? Can’t use DEFAULT_CARD_METADATA for some weird JS reason. But keep it updated!
      level: '',
      filter: {},
      benchmark: {},
      compare: null,
      show: {
        pageSize: 10,
        numberCell: true,
        percentages: false,
        graph: {
          series: [],
        },
        rows: {
          anonymizedRows: false,
          erroredRows: false,
        },
        columns: {
          cnps: false,
          cnpsBar: false,
          baseValues: [],
          average: true,
          count: true,
          benchmarks: true,
          expandedHeader: false,
        },
        sort: {
          orderAsc: false,
        },
      },
      goal: {
        // endDate: 'yyyy-MM-dd', // Future use // Perhaps we add this in a later stage
        endVal: null, // ? What is the end goal ?
        // startDate: format(new Date(), 'yyyy-MM-dd'), // Future use // ? From when does this goal start ?
        unit: 'cnpsValue', // 'cnpsValue' | 'promoter' // later also 'passive' | 'detractor' // ? What is the goal measuring ?
        // reverseStats: false, // Future use, when we implement 'detractor' // ? Should it be considered negative/positive ?
      },
      question: null,
      graphType: {
        selected: GRAPH_TYPES.queryTable,
        settings: {},
      },
      description: '',
      markdownDescription: {},
      name: '',
      order: '',
      ...this.metadata,
      ...retroactivelyAddedCardMetadata(this.metadata),
    };
  }

  getCompareKey() {
    return getCompareKey(this.metadata.compare);
  }

  isKeyMetric() {
    const gt = this.metadata?.graphType?.selected;
    return gt && Object.values(KEY_METRIC_GRAPH_TYPES).includes(gt);
  }

  isGoal() {
    const gt = this.metadata?.graphType?.selected;
    return gt && Object.values(GOAL_GRAPH_TYPES).includes(gt);
  }

  isQueryResult() {
    const gt = this.metadata?.graphType?.selected;
    return gt && Object.values(GRAPH_TYPES).includes(gt);
  }

  isText() {
    return this.metadata?.markdownDescription && !isEmpty(this.metadata?.markdownDescription);
  }

  getKeyMetricName() {
    switch (this.metadata.graphType?.selected) {
      case KEY_METRIC_GRAPH_TYPES.SWRanking:
        return $pgettext('Card.js - card name: Strengths & Weaknesses', 'Styrkor och svagheter');
      case KEY_METRIC_GRAPH_TYPES.RankingGauge:
        return $pgettext('Card.js - card name: Ranking', 'Företagsranking');
      case KEY_METRIC_GRAPH_TYPES.PerformanceScore:
        return $pgettext('Card.js - card name: Performance', 'Prestation inom företaget');
      case KEY_METRIC_GRAPH_TYPES.ResponseRate:
        // if (this.metadata.graphType?.settings?.[KEY_METRIC_GRAPH_TYPES.ResponseRate]?.type === 'bar') {
        //   return $pgettext('Card.js - card name: ResponseRate', 'Svarsfrekvens — %{step}', { step: translateTerm(this.metadata.level) });
        // }
        return $pgettext('Card.js - card name: ResponseRate', 'Svarsfrekvens');
      default:
        return '';
    }
  }

  hasQuestion() {
    return this.metadata !== undefined
      && this.metadata.question !== undefined
      && this.metadata.question !== null;
  }

  setupFromQuestionId(questionId) {
    return store.dispatch('getQuestionById', questionId)
      .then((question) => this.setupFromQuestion(question));
  }

  setupFromQuestion(question, isGoal) { // ? The defaults for cards
    this.metadata.question = question.id;
    this.metadata.graphType = returnGraphTypeObject(
      this.metadata,
      (isGoal ? GOAL_GRAPH_TYPES.GoalBar : GRAPH_TYPES.queryTable),
    );
    this.metadata.show.rows = {
      anonymizedRows: false,
      erroredRows: false,
      ...this.metadata.show.rows,
    };
    if (!isGoal) {
      switch (question.question_type) {
        case 'text':
          this.metadata.graphType = returnGraphTypeObject(this.metadata, GRAPH_TYPES.freeText);
          this.metadata.show.columns.benchmarks = false;
          break;
        case 'list':
          this.metadata.graphType = returnGraphTypeObject(this.metadata, GRAPH_TYPES.barGraph);
          this.metadata.show.percentages = true;
          this.metadata.show.numberCell = false;
          this.metadata.show.columns.benchmarks = false;
          break;
        case 'listmany':
          this.metadata.graphType = returnGraphTypeObject(this.metadata, GRAPH_TYPES.barGraph);
          this.metadata.show.percentages = true;
          this.metadata.show.numberCell = false;
          this.metadata.show.columns.benchmarks = false;
          break;
        case 'yesno':
          this.metadata.graphType = returnGraphTypeObject(this.metadata, GRAPH_TYPES.barGraph);
          this.metadata.show.percentages = true;
          this.metadata.show.columns.baseValues = ['ja', 'nej'];
          this.metadata.show.columns.benchmarks = true;
          break;
        case 'rating':
        // this.metadata.graphType = returnGraphTypeObject(this.metadata, 'LineGraph');
          if (question.options.cnps) {
            this.metadata.show.columns.cnps = true;
            this.metadata.show.columns.cnpsBar = true;
          } else {
            this.metadata.show.columns.cnps = false;
            this.metadata.show.average = true;
          }
          this.metadata.show.columns.benchmarks = true;
          break;
        default:
          break;
      }
    }
  }

  // removeQuestion() {
  //   this.metadata.name = '';
  //   this.metadata.question = null;
  //   this.metadata.description = '';
  //   this.metadata.show.columns.baseValues = [];
  //   this.metadata.show.pageSize = 5;
  //   this.metadata.compare = null;
  //   this.metadata.filter = {};
  //   this.metadata.benchmark = {};
  //   this.metadata.board = null;
  // }

  // removeKeyMetric() {
  //   this.metadata.name = '';
  //   this.metadata.question = null;
  //   this.metadata.description = '';
  //   this.metadata.show = [];
  //   this.metadata.show.pageSize = 5;
  //   this.metadata.compare = null;
  //   this.metadata.filter = {};
  //   this.metadata.benchmark = {};
  //   this.metadata.board = null;
  // }

  removing() {
    return this.isRemoving;
  }

  save(thenAssign = true, { orderAs } = {}) {
    if (this.id === undefined) {
      return this._api.create(this.section, orderAs ?? store.getters.getCardOrderAsInSection(this.section), this)
        .then((obj) => Object.assign(this, obj));
    }
    let promise = this._api.update(this.id, this);
    const storeCard = (card) => store.dispatch('setCard', { segmentId: store.getters.segmentId, card }) && card;
    if (thenAssign) return promise.then((obj) => Object.assign(this, obj)).then(storeCard); // Merges response with Card instance
    return promise.then(storeCard);
  }
}

export function cardFromQuestion(question, level, isGoal = false) {
  const card = new Card();
  card.metadata.level = level;
  card.setupFromQuestion(question, isGoal);
  return card;
}

export function cardFromKeyMetric({
  metadata = null, graphType = null, filter = null, compare = null, colClass = 'col-xs-12',
} = {}) {
  const card = new Card();
  if (isPlainObject(metadata)) card.metadata = { ...card.metadata, ...metadata };
  card.metadata.filter = filter;
  card.metadata.compare = compare;
  card.metadata.colClass = colClass;
  card.metadata.show = {
    question: [],
    sort: {
      orderAsc: true,
    },
    graph: {},
  };
  card.metadata.graphType = returnGraphTypeObject(card.metadata, graphType);
  return card;
}

export function createCard(instances) {
  return Array.isArray(instances)
    ? instances.map((instance) => new Card(instance))
    : new Card(instances);
}

/**
  ** cardsAPI.create
  * @param {number} sectionId
  * @param {IorderAsCard} [orderAs={ prevCardOrder: '', nextCardOrder: '' }]
  * @param {object} [params={}]
  * @param {object} [params.metadata={}]
  * @returns {Promise<CardClass>}
 */
function create(sectionId, orderAs = { prevCardOrder: '', nextCardOrder: '' }, { metadata = {} } = {}) {
  return postUrl('/databoard/api/cards/', {
    section: sectionId,
    previous_card_order: orderAs.prevCardOrder,
    next_card_order: orderAs.nextCardOrder,
    metadata,
  }).then(createCard);
}

/**
  ** cardsAPI.list
  * @param {number|null} [sectionId=null]
  * @returns {Promise<CardClass[]>}
 */
function list(sectionId = null) {
  return getBaseUrl('/databoard/api/cards/')
    .query({ section: sectionId })
    .then((response) => response.body.results, handleAuthError) // TODO: Add paginated response support & update JSDOC
    .then(createCard);
}

/**
  ** cardsAPI.get
  * @param {number} cardId
  * @returns {Promise<CardClass>}
 */
function get(cardId) {
  return getBaseUrl(`/databoard/api/cards/${cardId}/`)
    .then((response) => response.body, handleAuthError)
    .then(createCard);
}

/**
  ** cardsAPI.update
  * @param {number} cardId
  * @param {object} params
  * @param {number} [params.section=null]
  * @param {object} [params.metadata={}]
  * @returns {Promise}
 */
function update(cardId, { section = null, metadata = {} }) {
  return patchUrl(`/databoard/api/cards/${cardId}/`, {
    ...(section && { section }),
    ...(metadata && { metadata }),
  });
}

/**
  ** cardsAPI.remove
  * @param {number} cardId
  * @returns {Promise}
 */
function remove(cardId) {
  return deleteUrl(`/databoard/api/cards/${cardId}/`);
}

/**
  ** cardsAPI.move
  * @param {number} cardId
  * @param {IorderAsCard} [orderAs={ prevCardOrder: '', nextCardOrder: '' }]
  * @returns {Promise}
 */
function move(cardId, orderAs = { prevCardOrder: '', nextCardOrder: '' }, { toSectionId = null } = {}) {
  return patchUrl(`/databoard/api/cards/${cardId}/`, {
    previous_card_order: orderAs.prevCardOrder,
    next_card_order: orderAs.nextCardOrder,
    ...(toSectionId && { section: toSectionId }),
  });
}

const _api = {
  get,
  list,
  update,
  create,
  remove,
  move,
  Card,
};

Card.prototype._api = _api;

export default _api;
