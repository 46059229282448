<template>
  <span
    ref="contentEditEl"
    v-bind="$attrs"
    :class="{ 'content-editing': editing }"
    :contenteditable="editing"
    class="content-edit"
    data-input="true"
    @keydown.esc.prevent="onCancel"
    @keydown.enter.prevent="onSave"
    @blur="onEdit"
    @paste="onPaste"
    v-text="text"
  />
  <transition name="slide-fade">
    <span
      v-if="editing"
      class="ml-1 no-user-select"
    >
      <!-- <i
        v-if="showIcon"
        class="zmdi zmdi-edit tc-color-brand mr-0.5"
      /> -->
      <button
        type="button"
        class="btn btn-thin btn-primary no-user-select"
        @click="onSave"
      >
        {{ $gettext('Spara') }}
      </button>
      <button
        type="button"
        class="btn btn-thin btn-secondary no-user-select"
        @click="onCancel"
      >
        {{ $gettext('Avbryt') }}
      </button>
    </span>
  </transition>
</template>

<script>
import { striphtml } from 'Utils/general';

export default {
  name: 'ContentEditable',
  props: {
    content: String,
    isEditing: Boolean,
    // showIcon: {
    //   type: Boolean,
    //   default: false,
    // },
  },
  emits: ['save', 'cancel'],
  data() {
    return {
      text: '',
      editing: false,
    };
  },
  watch: {
    isEditing(newVal) {
      this.editing = newVal;
    },
  },
  mounted() {
    this.setText(this.content);
    this.editing = this.isEditing ?? false;
  },
  methods: {
    setText(string) {
      this.text = striphtml(string.trim());
    },
    onEdit(evt) {
      this.setText(evt.target.innerText);
    },
    onPaste(evt) {
      setTimeout(() => { // ? The pasted string doesn’t exist yet
        this.onEdit(evt);
      }, 20);
    },
    onCancel(e) {
      this.editing = !this.editing;
      setTimeout(() => { // ? Because blur is triggered by this.editing
        this.setText(this.content);
        this.$emit('cancel');
      }, 0);
    },
    onSave(evt) {
      if (evt.target.classList.contains('content-edit')) this.setText(evt.target.innerText);
      else if (this.$refs?.contentEditEl) this.setText(this.$refs.contentEditEl.innerText);
      this.editing = !this.editing;
      this.$emit('save', this.text);
    },
  },
};
</script>
