<template>
  <div>
    <label
      class="block"
      v-text="$pgettext('Label — Date column', 'Datum')"
    />
    <div class="badge-list cursor-pointer">
      <tc-date-picker
        ref="tcDatePickerEl"
        v-model:dateObject="selectedDate"
        :empty-allowed="(isCard || isTemporaryWithinModal)"
        :removable="(isCard || isTemporaryWithinModal) && !isEmpty(selectedDate)"
        :use-focus-briefly="isCard || isTemporaryWithinModal"
        use-badge-styling
        @remove="handleRemove"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { isEmpty } from 'lodash-es';
import TcDatePicker from 'Components/parts/TcDatePicker';
import { store } from '@/store';

const props = defineProps({
  dateMetadata: {
    type: Object,
    default: () => ({}),
  },
  isCard: {
    type: Boolean,
    default: false,
  },
  isTemporary: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['update:dateMetadata']);

const tcDatePickerEl = ref(null);

const selectedDate = computed({
  get: () => props.dateMetadata ?? {},
  set: (value) => emit('update:dateMetadata', value),
});

const handleRemove = () => {
  selectedDate.value = {};
};

const isTemporaryWithinModal = computed(() => props.isTemporary && store.state.cards.modalCard?.metadata !== undefined);

const timers = ref([]);
onMounted(() => {
  if ((props.isCard || props.isTemporary) && store.state.cards.modalCardOpenedFrom === 'date') {
    timers.value.push(setTimeout(() => tcDatePickerEl.value?.openDatePicker?.(), 200));
    timers.value.push(setTimeout(() => store.dispatch('setModalCardOpenedFrom', null), 2000));
  }
});
onUnmounted(() => {
  timers.value.forEach((timer) => clearTimeout(timer));
});
</script>
