<template>
  <div>
    <div
      v-if="actions && actions.length > 0"
      ref="actions"
      class="flex-row-actions align-items-baseline justify-space-between"
    >
      <div class="px-1 filter-popover-boxes">
        <p class="small-text subtle-text inline-block pr-1">
          {{ $gettext('Mål') }}
          <template v-if="question?.question_type">
            <span>・</span>
            <span
              v-tooltip="{ content: () => questionTypesTooltip(question) }"
              class="cursor-help"
            >
              {{ translateTerm(getQuestionType(question)) }}
              <i class="zmdi zmdi-help-outline" />
            </span>
          </template>
          <template v-if="card?.metadata?.level || question?.level">
            <span>・</span>
            <span>{{ translateTerm(card?.metadata?.level || question?.level) }}</span>
          </template>
        </p>
      </div>
      <section
        class="filter-popover-boxes filter-popover-btns hide-print"
      >
        <template v-if="actions.indexOf('downloadPng') > -1">
          <button
            v-tooltip="downloadingCurrentCard
              ? $gettext('Laddar ner cardet som bild')
              : $gettext('Ladda ner cardet som bild')"
            :aria-disabled="downloadingCurrentCard ||cardLoading"
            class="btn btn-icon-text btn-thin btn-secondary strong"
            :tabindex="visible ? null : -1"
            @click.prevent="downloadCardPng"
          >
            <span v-if="!downloadingCurrentCard">
              <i class="zmdi zmdi-download ml-0" /> {{ $gettext('Ladda ner') }}
            </span>
            <span v-else>
              <i class="zmdi zmdi-spinner zmdi-hc-spin ml-0" />
              {{ $gettext('Laddar ner') }}
            </span>
          </button>
        </template>
        <template v-if="actions.indexOf('editButton') > -1">
          <button
            v-tooltip="$gettext('Ändra cardet')"
            class="btn btn-primary btn-thin btn-icon-text"
            :tabindex="visible ? null : -1"
            @click.prevent="openCardEditModal"
          >
            <i class="zmdi zmdi-edit ml-0" /> <span>{{ $gettext('Ändra') }}</span>
          </button>
        </template>
      </section>
    </div>
    <div class="tc-card-header-flex-title">
      <h3 class="text-wrap">
        <span>{{ cardName }}</span>
      </h3>
      <p
        v-if="description || card?.metadata?.description"
        class="text-wrap-paragraphable subtle-text my-0 small-text"
      >
        {{ description || card?.metadata?.description }}
      </p>
    </div>
    <card-filter-badges
      :card-metadata="card?.metadata || {}"
      :compiled-filter="compiledFilter"
      @open-edit-modal="isInBoard ? openCardEditModal($event) : null"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { processText, translateTerm } from 'Utils/general';
import { getQuestionType, questionTypesTooltip } from 'Utils/question';
import { clone, openReportModal } from 'Utils/card';
import dynamicImport from 'Utils/dynamicImport';
import CardFilterBadges from 'Components/parts/filters/CardFilterBadges';

export default {
  name: 'GoalResultHeader',
  components: {
    CardFilterBadges,
  },
  props: {
    cardLoading: Boolean,
    card: {
      type: Object,
      required: true,
    },
    question: Object,
    board: Object,
    actions: Array,
    links: Array,
    visible: {
      type: Boolean,
      default: true,
    },
    contextFilter: {
      type: Object,
      default: () => ({}),
    },
    compiledFilter: {
      type: Object,
      default: () => ({}),
    },
    isInBoard: {
      type: Boolean,
      default: false,
    },
    previewMode: {
      type: Boolean,
      default: false,
    },
    temporaryCard: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
    hydrateProps: Object,
  },
  emits: ['update-drag-area', 'download-card-png'],
  computed: {
    ...mapGetters([
      'textFilter',
      'downloadingCard',
      'showPaywalls',
    ]),
    downloadingCurrentCard() {
      return this.downloadingCard.includes(this.card.id);
    },
    questionType() { return this.question?.question_type || ''; },
    hasQuestion() { return this.question?.id; },
    graphType() { return this.card?.metadata?.graphType?.selected; },
    asideSettings() {
      const settings = [];
      if (this.links && this.links.length > 0) {
        settings.push({
          value: 'links',
          label: this.$pgettext('Title — Card Actions', 'Alternativ'),
          context: this.links.reduce((acc, link) => {
            if (link === 'clone') {
              acc.push({
                click: clone,
                disabled: !this.hasQuestion,
                label: this.$gettext('Duplicera cardet'),
              });
            } else if (link === 'createReport') {
              acc.push({
                click: this.openReportModal,
                label: this.$pgettext('Action — Card header createReport action', 'Skapa en rapport av card'),
              });
            }
            return acc;
          }, []),
        });
      }
      settings.push({
        value: 'flags',
        label: this.$gettext('Visa'),
      });
      return settings;
    },
    cardName() {
      return this.name
        || this.card?.metadata?.name?.length > 0 && this.card.metadata.name
        || this.translatedQuestion;
    },
    translatedQuestion() {
      return this.question?.translation
        && processText(this.question.translation.question, this.textFilter)
        || this.$gettext('Laddar fråga...');
    },
  },
  watch: {
    compiledFilter(newVal) {
      if (newVal) this.$emit('update-drag-area');
    },
  },
  methods: {
    ...mapActions([
      'openModal',
      'setDownloadingCard',
    ]),
    translateTerm,
    getQuestionType,
    questionTypesTooltip,
    downloadCardPng() {
      if (this.downloadingCurrentCard || this.cardLoading) return;
      this.setDownloadingCard(this.card.id);
      this.$emit('download-card-png');
    },
    openCardEditModal() {
      const CardEditDetails = dynamicImport(() => import(/* webpackChunkName: "CardEditDetails", webpackPrefetch: true */ 'Components/parts/filters/CardEditDetails'));
      this.openModal({
        name: 'CardEditDetails',
        component: CardEditDetails,
        size: 'xlarge',
        dataObject: {
          props: {
            board: this.board,
            card: this.card,
            contextFilter: this.contextFilter,
            asideSettings: this.asideSettings,
            hydrateProps: this.hydrateProps,
            temporaryCard: this.temporaryCard,
          },
        },
      });
    },
    openReportModal() {
      openReportModal({
        props: { label: this.cardName },
        callback: (newDataObject) => {
          this.$nextTick(() => {
            if (this.isInBoard) this.$emit('update-drag-area');
          });
        },
      });
    },
  },
};
</script>
