/**
 * For caching of sections requests
 * updates will remove cache and get sections again
 * components need to watch getters.sections
 */
import { isEmpty } from 'lodash-es';
import sectionsAPI from 'API/sections';

// const sectionOption = (board) => ({ value: board.id, label: stripBoardLabel(board.label, board.is_public) });

export default {
  state: {
    sections: {}, // { segmentId: Map(sectionId: <SectionType>) }
  },
  mutations: {
    SET_SECTION(state, { segmentId, section }) {
      if (!state.sections[segmentId]) {
        state.sections[segmentId] = new Map();
      }

      state.sections[segmentId].set(section.id, section);
    },
    SET_SECTIONS(state, { segmentId, sections }) {
      if (!state.sections[segmentId]) {
        state.sections[segmentId] = new Map();
      }

      sections.forEach((section) => {
        state.sections[segmentId].set(section.id, section);
      });
    },
    CLEAR_SECTION(state, { segmentId, sectionId }) {
      state.sections[segmentId]?.delete(sectionId);
    },
    CLEAR_SECTIONS(state, segmentId = null) {
      if (typeof segmentId === 'number') {
        state.sections[segmentId] = new Map();
      }
      if (segmentId === null) {
        state.sections = {};
      }
    },
  },
  getters: {
    allSectionsByCurrentSegment: (state, getters) => state.sections?.[getters?.segmentId]
      && [...state.sections[getters.segmentId].values()] || [],
    getSectionsByBoardId: (state, getters) => (boardId) => getters.allSectionsByCurrentSegment
      .filter((section) => section?.board === boardId) || [],
    getSectionById: (state) => (id) => Object.values(state.sections)
      ?.find((sectionsMap) => sectionsMap?.get(id))
      || {},
  },
  actions: {
    setSection({ commit }, { segmentId, section }) {
      commit('SET_SECTION', { segmentId, section });
      return true;
    },
    setSections({ commit }, { segmentId, sections }) {
      commit('SET_SECTIONS', { segmentId, sections });
      return true;
    },
    async createSection({ commit, getters }, {
      segmentId = getters.segmentId,
      boardId,
      orderAs,
      sectionOptions = { name: '', description: '', color: '' },
    }) {
      const createdSection = await sectionsAPI.create(boardId, orderAs, sectionOptions);
      commit('SET_SECTION', { segmentId, section: createdSection });
      return createdSection;
    },
    removeSection({ commit, getters }, {
      segmentId = getters.segmentId,
      sectionId,
      apiCall = true,
    }) {
      const cardsInSection = getters.getCardsBySectionId(sectionId);
      commit('CLEAR_SECTION', { segmentId, sectionId });
      cardsInSection.forEach((card) => commit('CLEAR_CARD', { segmentId, cardId: card.id }));
      if (apiCall) return sectionsAPI.remove(sectionId).then((res) => sectionId);
      return true;
    },
    async moveSection({ dispatch, state, getters }, {
      segmentId = getters.segmentId,
      sectionId,
      orderBetween: {
        prevSectionId = null,
        nextSectionId = null,
      },
    }) {
      const section = state.sections?.[segmentId].get(sectionId) || {};
      if (isEmpty(section)) return new Error('Section not found');

      const { order: prevSectionOrder = '' } = state.sections?.[segmentId].get(prevSectionId) || '';
      const { order: nextSectionOrder = '' } = state.sections?.[segmentId].get(nextSectionId) || '';
      const orderAs = { prevSectionOrder, nextSectionOrder };

      try {
        const response = await sectionsAPI.move(sectionId, orderAs);
        const updatedSection = await dispatch('setSection', ({ segmentId, section: response }));

        return updatedSection && response;
      } catch (err) {
        return err;
      }
    },
  },
};
