import { h as createElement } from 'vue';
import { klona } from 'klona';
import Emitter from 'tiny-emitter';
import { confirm } from 'Modal/dialogBoxes';

export const paneEmitter = new Emitter();

export function openPane({ context, component }) {
  return paneEmitter.emit('open-pane', { context, component });
}

/**
 ** closePane
 * @param {Object} areYouSure:<string>, Set `areYouSure` to a string and closePane will wait for confirmation
 */
export function closePane({ areYouSure } = {}) {
  // TODO: Doesn't have support for halting openPane yet, skipping that implementation to keep time
  if (typeof areYouSure === 'string') {
    return confirm(
      areYouSure,
      this.$pgettext('DialogButton — AreYouSure confirm', 'Spara inte'),
      this.$pgettext('DialogButton — AreYouSure dismiss', 'Avbryt'),
      'danger',
    )
      .then((decidedToClose) => {
        if (decidedToClose) return paneEmitter.emit('close-pane');
        return false;
      })
      .catch((err) => err);
  }
  return paneEmitter.emit('close-pane');
}

const Pane = {
  data() {
    return {
      context: {},
      toggled: false,
      component: null,
    };
  },
  mounted() {
    paneEmitter.on('update-pane', (context = {}) => {
      this.context = context;
    });
    paneEmitter.on('open-pane', ({ context = {}, component = null }) => {
      this.toggled = true;
      this.context = context;
      this.component = component;
    });
    paneEmitter.on('close-pane', () => {
      this.toggled = false;
      this.component = null;
      this.context = {};
    });
  },
  render() {
    if (this.toggled && this.component !== null) {
      return createElement('div', {
        class: 'col-flex ',
        tabindex: '-1',
      }, [
        createElement('div', {
          class: 'tc-pane',
          tabindex: '-1',
        }, [
          createElement(klona(this.component), {
            context: this.context,
          }),
        ]),
      ]);
    }
    return null;
  },
};
export default Pane;
