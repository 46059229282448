<template>
  <li class="tc-list-group-item">
    <div class="flex-row sticky tc-list-group-item-header">
      <h3 class="flex-col">
        {{ segment.name }}
      </h3>
      <div class="chip-set chip-set-sm flex-col">
        <button
          class="chip chip-manage"
          tabindex="0"
          @click.prevent="settings"
        >
          <i class="chip-icon zmdi-hc-lg zmdi zmdi-edit" />
          <span class="chip-label">
            <span>{{ translations.editSegment }}</span>
          </span>
        </button>
        <button
          class="chip chip-manage"
          tabindex="0"
          @click.prevent="remove"
        >
          <i class="chip-icon zmdi-hc-lg zmdi zmdi-delete" />
          <span class="chip-label">
            <span>{{ translations.deleteSegment }}</span>
          </span>
        </button>
      </div>
    </div>
    <div class="flex-row direction-column">
      <segment-sub-list
        v-if="showBoards"
        class="flex-col"
        list-type="board"
        :single-segment-id="segment.id"
      />
      <segment-sub-list
        v-if="showUsers"
        class="flex-col"
        list-type="user"
        :single-segment-id="segment.id"
      />
    </div>
  </li>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import segmentAPI from 'API/segment';
import { confirm } from 'Modal/dialogBoxes';
import { openPane /* , closePane */ } from 'Components/parts/pane/Pane';
import PaneSettingsSegment from 'Components/parts/pane/PaneSettingsSegment';
import SegmentSubList from './SegmentSubList';

export default {
  components: {
    SegmentSubList,
  },
  props: {
    initialSegment: {
      required: true,
      type: Object,
    },
    showRelated: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  emits: ['removed-segment'],
  data() {
    return {
      segment: this.initialSegment,
      paneContext: {
        segment: this.initialSegment,
        onRemove: (segmentId) => {
          this.$emit('removed-segment', segmentId);
        },
        onSaved: (segment) => {
          if (segment?.id) {
            this.segment.name = segment.name;
            this.segment.tags = segment.tags;
            this.segment.query = segment.query;
          }
        },
      },
    };
  },
  computed: {
    ...mapGetters([
      'fetchingBoards',
      'segmentId',
    ]),
    showBoards() {
      return this.showRelated.includes('boards');
    },
    showUsers() {
      return this.showRelated.includes('users');
    },
    translations() {
      return {
        editSegment: this.$pgettext('Label - Button in segment manager', 'Redigera Segment'),
        deleteSegment: this.$pgettext('Label - Button in segment manager', 'Radera Segment'),
      };
    },
  },
  methods: {
    ...mapActions([
      'notify',
      'setSegment',
      'setupSegment',
      'setLastSearchState',
      'fetchMe',
    ]),
    remove(e) {
      const text = this.$pgettext(
        'Confirm — remove segment warning text',
        'Är du säker på att du vill ta bort segmentet "%{segment}"? Alla medlemmar kommer kastas ut ur detta segment.',
        { segment: this.segment.name },
      );
      confirm(text, this.$gettext('Ja, ta bort segmentet'), this.$gettext('Avbryt'), 'danger')
        .then(this.confirmRemove(this.segment.id))
        .catch(this.removeError(this.segment.id));
    },
    confirmRemove(id) {
      return (remove) => {
        if (remove) {
          return segmentAPI.remove(id).then(() => {
            this.setLastSearchState?.({});
            this.fetchMe(); // updates currentSegments in store
            if (this.segmentId === id) {
              this.setupSegment(this.$router);
            }
            if (this.paneContext && typeof this.paneContext.onRemove === 'function') {
              this.paneContext.onRemove(id);
            }
          });
        }
        return Promise.resolve();
      };
    },
    removeError(id) {
      return (error) => this.notify({
        level: 'error',
        text: `${this.$pgettext('Error — Couldn’t remove segment', 'Kunde inte ta bort segmentet')} (${id}). ${error.text || error}`,
      });
    },
    settings() {
      // TODO: open in new modal instead
      openPane({
        component: PaneSettingsSegment,
        context: this.paneContext,
      });
    },
  },
};
</script>
