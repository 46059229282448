import { klona } from 'klona';
import { isEmpty, set } from 'lodash-es';
import cardsAPI from 'API/cards';
import { getCardOrderAs } from 'Utils/card';

export default {
  state: {
    cards: {}, // { segmentId: Map(cardId: <CardType>) }
    modalCard: {},
    modalCardOpenedFrom: null,
    downloadingCard: [],
    activeRow: '',
    shownCardIds: [],
  },
  mutations: {
    SET_CARDS_SHOWN(state, cardIds = []) {
      state.shownCardIds = [...new Set([...state.shownCardIds, ...cardIds])];
    },
    CLEAR_CARD_SHOWN(state) {
      state.shownCardIds = [];
    },
    SET_CARD(state, { segmentId, card }) {
      if (!state.cards[segmentId]) {
        state.cards[segmentId] = new Map();
      }

      state.cards[segmentId].set(card.id, card);
    },
    SET_CARDS(state, { segmentId, cards }) {
      if (!state.cards[segmentId]) {
        state.cards[segmentId] = new Map();
      }

      cards?.forEach((card) => {
        state.cards[segmentId].set(card.id, card);
      });
    },
    CLEAR_CARD(state, { segmentId, cardId }) {
      state.cards[segmentId].delete(cardId);
    },
    CLEAR_CARDS(state, segmentId = null) {
      if (typeof segmentId === 'number') {
        state.cards[segmentId] = new Map();
      }
      if (segmentId === null) {
        state.cards = {};
      }
    },

    // ? modalCard
    SET_MODAL_CARD(state, card) {
      state.modalCard = card;
    },
    SET_MODAL_CARD_OPENED_FROM(state, val) {
      state.modalCardOpenedFrom = val;
    },

    SET_ACTIVE_ROW(state, value) {
      state.activeRow = value;
    },
    CLEAR_ACTIVE_ROW(state) {
      state.activeRow = '';
    },
    SET_DOWNLOADING_CARD(state, value) {
      state.downloadingCard.push(value);
    },
    REMOVE_DOWNLOADING_CARD(state, value) {
      if (value === null) state.downloadingCard = [];
      state.downloadingCard = state.downloadingCard.filter((cardId) => cardId !== value);
    },
  },
  getters: {
    allCardsByCurrentSegment: (state, getters) => state.cards?.[getters?.segmentId]
      && [...state.cards[getters.segmentId].values()] || [],
    getCardById: (state, getters) => (cardId) => state.cards?.[getters?.segmentId]?.get(cardId) || false,
    getCardsBySectionId: (state, getters) => (sectionId) => getters.allCardsByCurrentSegment
      .filter((card) => card?.section === sectionId),
    getCardOrderAsInSection: (state, getters) => (sectionId) => {
      const cardOrders = getters.getCardsBySectionId(sectionId).map((card) => card.order);
      return getCardOrderAs(cardOrders); // ? Currently only as last
    },
    getCardsByBoardId: (state, getters) => (boardId) => {
      let filteredByBoard = [];
      state?.cards?.[getters?.segmentId]?.forEach((card) => {
        if (card?.board === boardId) filteredByBoard.push(card);
      });
      return filteredByBoard;
    },
    // allCardsByBoardId: (state) => (boardId) => Object.values(state?.cards?.[Number(boardId)] || {})
    //   .reduce((acc, cards) => { acc.push(...cards); return acc; }, []),
    // getCardById: (state) => (id) => state?.cards?.find((board) => board.find((card) => card?.id === id)),
    // getCardByBoardIdAndId: (state) => (boardId, id) => state?.cards?.[boardId]?.find((card) => card?.id === id),

    // ? modalCard
    modalCard: (state) => state.modalCard,
    downloadingCard: (state) => state.downloadingCard,
  },
  actions: {
    setCardShown({ commit }, cardIds) {
      commit('SET_CARDS_SHOWN', cardIds);
    },
    clearCardShown({ commit }) { commit('CLEAR_CARD_SHOWN'); },
    // cards
    setCard({ commit }, { segmentId, card }) {
      commit('SET_CARD', { segmentId, card });
      return true;
    },
    setCards({ commit }, { segmentId, cards }) {
      if (!cards) return false;
      commit('SET_CARDS', { segmentId, cards });
      return true;
    },
    updateCard({ commit, getters }, {
      segmentId = getters.segmentId,
      card,
      apiCall = true,
    }) {
      commit('SET_CARD', { segmentId, card });
      if (apiCall) return cardsAPI.update(card.id, card);
      return true;
    },
    createCard({ commit, getters }, {
      segmentId = getters.segmentId,
      sectionId,
      orderAs,
      card, // Must be a Card Class instance
      apiCall = true,
    }) {
      if (apiCall) {
        card.section = sectionId;
        return card.save(true, { orderAs })
          .then((res) => {
            card.order = res.order;
            commit('SET_CARD', { segmentId, card });
            return card;
          });
      }
      commit('SET_CARD', { segmentId, card });
      return true;
    },
    removeCard({ commit, getters }, {
      segmentId = getters.segmentId,
      cardId,
      card = null, // ? Optional. Card Class. If you want `isRemoving` to work
      apiCall = true,
    }) {
      if (apiCall) {
        const prom = typeof card?.remove === 'function'
          ? card.remove()
          : cardsAPI.remove(cardId);

        return prom.then(commit('CLEAR_CARD', { segmentId, cardId }));
      }
      commit('CLEAR_CARD', { segmentId, cardId });
      return true;
    },
    async moveCard({ dispatch, state, getters }, {
      fromSectionId,
      toSectionId,
      cardId,
      orderBetween: {
        prevCardId = null,
        nextCardId = null,
      },
    }) {
      const segmentId = getters.segmentId;
      const card = klona(state.cards?.[segmentId].get(cardId)) || {};
      if (isEmpty(card)) return new Error('Card not found');

      const { order: prevCardOrder = '' } = state.cards?.[segmentId].get(prevCardId) || '';
      const { order: nextCardOrder = '' } = state.cards?.[segmentId].get(nextCardId) || '';

      const orderAs = { prevCardOrder, nextCardOrder };
      const movingBetweenSections = fromSectionId !== toSectionId;
      const newCard = await cardsAPI.move(cardId, orderAs, movingBetweenSections ? { toSectionId } : {});
      const updatedCard = await dispatch('setCard', ({ segmentId, card: { board: card.board, ...newCard } }));

      return updatedCard && newCard;
    },

    // modalCard
    setModalCard({ state, commit }, card = {}) {
      const newCard = isEmpty(state.modalCard)
        ? card
        : { ...klona(state.modalCard), ...card };
      commit('SET_MODAL_CARD', newCard);
      return newCard;
    },
    setModalCardPath({ state, commit }, { path = '', value = null } = {}) {
      const newCard = klona(state.modalCard);
      set(newCard, path, value);
      commit('SET_MODAL_CARD', newCard);
      return newCard;
    },
    clearModalCard({ commit }) {
      commit('SET_MODAL_CARD', {});
    },
    setModalCardOpenedFrom({ commit }, val = null) {
      commit('SET_MODAL_CARD_OPENED_FROM', val);
    },

    // ActiveRow
    setActiveRow({ commit }, val = '') {
      commit('SET_ACTIVE_ROW', val);
      return val;
    },
    clearActiveRow({ commit }) {
      commit('CLEAR_ACTIVE_ROW');
    },
    setDownloadingCard({ commit }, val) {
      commit('SET_DOWNLOADING_CARD', val);
      return val;
    },
    removeDownloadingCard({ commit }, val) {
      commit('REMOVE_DOWNLOADING_CARD', val);
      return val;
    },
  },
};
