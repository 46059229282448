/* eslint-disable max-classes-per-file */
import request from 'superagent';
import Model from 'API/model';
import { format } from 'Utils/dateHelpers';
import { handleAuthError, getToken } from 'Utils/authHelpers';
import { superagentCacheBust, superagentSetDashboardOriginPage, superagentLanguage } from 'Utils/apiHelpers';
import { BASE_URL } from 'Utils/urls';
import { ErrCodeError } from 'Utils/errors';
import { store } from '@/store';
import gettext from '@/gettext';
// ? Beware of importing here, could be causing circular dependencies

const { $pgettext } = gettext;

/**
 * createClass to be able to `Card extends Model`
 * and add methods to specific api
 */
export function createClass(InstanceClass = Model) {
  return (instances) => {
    const isList = Array.isArray(instances);
    if (isList) {
      return instances.map((instance) => new InstanceClass(instance));
    }
    return new InstanceClass(instances);
  };
}

export function defer() {
  let deferred = {
    promise: null,
    resolve: null,
    reject: null,
  };

  deferred.promise = new Promise((resolve, reject) => {
    deferred.resolve = resolve;
    deferred.reject = reject;
  });

  return deferred;
}

export function prefixTag(tag, fix = 'tags__') {
  return tag?.substring(0, fix.length) !== fix ? `${fix}${tag}` : tag;
}

export function unprefixTag(tag, fix = 'tags__') {
  return tag?.substring(0, fix.length) === fix ? tag.substring(fix.length) : tag;
}

export function suffixTag(tag, fix = '__in') {
  return tag?.substring(tag.length - fix.length) !== fix ? `${tag}${fix}` : tag;
}

export function unsuffixTag(tag, fix = '__in') {
  return tag?.substring(tag.length - fix.length) === fix ? tag.substring(0, tag.length - fix.length) : tag;
}

export function handleServerError(error) {
  if (error.status === 504) throw new ErrCodeError({ response: { body: $pgettext('Error - handleServerError 504', 'Hämtning av data tog för lång tid, prova igen senare eller kontakta oss.'), status: 504 } });
  if (error.status >= 500) {
    store.dispatch('notify', {
      text: `${error.status} (${error.message})`,
      level: 'error',
    });
  }
  throw new ErrCodeError(error);
}

export function baseRequest(callback) {
  const token = getToken().token;
  return callback(request)
    .set('Authorization', `JWT ${token}`)
    .use(superagentLanguage)
    .accept('application/json')
    .then((response) => response.body, handleAuthError)
    .catch(handleServerError);
}

export function baseRequestNoCache(callback) {
  const token = getToken().token;
  return callback(request)
    .set('Authorization', `JWT ${token}`)
    .use(superagentLanguage)
    .use(superagentCacheBust)
    .accept('application/json')
    .then((response) => response.body, handleAuthError)
    .catch(handleServerError);
}

export function getBaseUrl(url, useDashboardLanguage) {
  const token = getToken().token;
  const r = request
    .get(`${BASE_URL}${url}`)
    .use((req) => superagentLanguage(req, useDashboardLanguage))
    .use(superagentSetDashboardOriginPage)
    .accept('application/json');
  if (token) return r.set('Authorization', `JWT ${token}`);
  return r;
}

export function getUrl(url, useDashboardLanguage = false) {
  return getBaseUrl(url, useDashboardLanguage)
    .then((response) => response.body, handleAuthError)
    .catch(handleServerError);
}

export function getIndexHtml() {
  if (process.env.NODE_ENV === 'test') return null;
  return request.get('/index.html')
    .accept('text/html')
    .catch((err) => {});
}

export function postBaseUrl(url, data = null, useBrowserLang = false) {
  const token = getToken().token;
  let r = request
    .post(`${BASE_URL}${url}`)
    .use(useBrowserLang ? (skip) => skip : superagentLanguage)
    .use(superagentSetDashboardOriginPage)
    .accept('application/json')
    .on('abort', () => Promise.resolve());
  if (token) r = r.set('Authorization', `JWT ${token}`);

  if (data !== null) return r.send(data);
  return r;
}

export function postUrl(url, data = null, useBrowserLang = false) {
  return postBaseUrl(url, data, useBrowserLang)
    .then((response) => response.body, handleAuthError)
    .catch(handleServerError);
}

export function putBaseUrl(url, data = null) {
  const token = getToken().token;
  const r = request
    .put(`${BASE_URL}${url}`)
    .use(superagentLanguage)
    .use(superagentSetDashboardOriginPage)
    .set('Authorization', `JWT ${token}`)
    .accept('application/json');
  if (data !== null) return r.send(data);
  return r;
}

export function putUrl(url, data = null) {
  return putBaseUrl(url, data)
    .then((response) => response.body, handleAuthError)
    .catch(handleServerError);
}

export function patchBaseUrl(url, data = null) {
  const token = getToken().token;
  const r = request
    .patch(`${BASE_URL}${url}`)
    .use(superagentLanguage)
    .use(superagentSetDashboardOriginPage)
    .set('Authorization', `JWT ${token}`)
    .accept('application/json');
  if (data !== null) return r.send(data);
  return r;
}

export function patchUrl(url, data = null) {
  return patchBaseUrl(url, data)
    .then((response) => response.body, handleAuthError)
    .catch(handleServerError);
}

export function deleteBaseUrl(url, data = null) {
  const token = getToken().token;
  const r = request
    .delete(`${BASE_URL}${url}`)
    .use(superagentLanguage)
    .use(superagentSetDashboardOriginPage)
    .set('Authorization', `JWT ${token}`)
    .accept('application/json');
  if (data !== null) return r.send(data);
  return r;
}

export function deleteUrl(url, data = null) {
  return deleteBaseUrl(url, data)
    .then((response) => response.body, handleAuthError)
    .catch(handleServerError);
}

// export function downloadUrl(url) {
//   window.open(url, '_self');
// }

export function downloadPdfUrl(url, { label, date }) {
  return request.get(url)
    .use(superagentLanguage)
    .responseType('blob')
    .accept('application/pdf')
    .then((res) => res.body.arrayBuffer())
    .then((res) => {
      const file = new Blob([res], { type: 'application/pdf' });
      const fileURL = window?.URL.createObjectURL(file) ?? null;
      const link = document.createElement('a');
      link.href = fileURL;
      const dateFormatted = date ? format(new Date(date), 'yyMMdd') || null : null;
      if (label && date) link.download = `trustcruit-export-${label}-${dateFormatted}.pdf`;
      else link.download = 'trustcruit-export.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      if (typeof window !== 'undefined') window.URL.revokeObjectURL(link.href);
    })
    .catch(handleServerError);
}
