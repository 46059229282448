<template>
  <div>
    <label class="block">Benchmark</label>
    <div class="badge-list">
      <ul
        v-if="badges.length > 0"
        class="badge-list plain-list"
      >
        <VMenu
          v-for="(item, index) in badges"
          :key="item.slug+index"
          v-model:shown="badgeRefs[item.slug]"
          theme="select"
          auto-hide
          :triggers="['click', 'touch']"
          :auto-boundary-max-size="false"
          :auto-size="false"
          :disabled="isPopperDisabled"
          :container="false"
          :instant-move="true"
          :flip="!(isCard || isTemporary)"
          :strategy="(isCard || isTemporary) ? 'absolute' : 'fixed'"
          :distance="6"
        >
          <BadgeListItemBenchmark
            class="cursor-pointer"
            :item="item"
            :removable="item.slug !== 'default'"
            tabindex="0"
            :class="{
              'focus-briefly': (isCard || isTemporary)
                && wasOpenedFromBenchmark(store.state.cards.modalCardOpenedFrom, true) === item.slug
            }"
            :should-open-badges="
              shouldOpenBadges
                || (
                  (isCard || isTemporary)
                  && wasOpenedFromBenchmark(store.state.cards.modalCardOpenedFrom, true) === item.slug
                )
            "
            @remove="handleRemove"
            @update:shown="handleUpdateShown"
          />

          <template #popper>
            <FilterDropdownsBenchmarkPopper
              :include-group="item.boxRowKey"
              :badge-item="item"
              :dropdown-settings="{ multiselect: true, dropdownFlavor: 'benchmark' }"
              @selected="handleSelected"
            />
          </template>
        </VMenu>
      </ul>

      <!--  the vmenu works good when I override the triggers and deleted focus -->
      <VMenu
        v-if="shouldShowAddBenchmark"
        auto-hide
        theme="select"
        :triggers="['click', 'touch']"
        :auto-boundary-max-size="false"
        :auto-size="false"
        :container="false"
        :instant-move="true"
        :flip="!(isCard || isTemporary)"
        :strategy="(isCard || isTemporary) ? 'absolute' : 'fixed'"
        :distance="6"
      >
        <button
          v-tooltip="$pgettext('Tooltip - FilterDropdownsBenchmark plus icon', 'Lägg till en ny benchmark')"
          class="badge-list-btn btn btn-link btn-icon-text hide-print"
          :class="{
            'focus-briefly': (isCard || isTemporary)
              && wasOpenedFromBenchmark(store.state.cards.modalCardOpenedFrom)
              && !badges.find(
                (item) => item.slug === wasOpenedFromBenchmark(store.state.cards.modalCardOpenedFrom, true)
              )
          }"
        >
          <i class="zmdi zmdi-plus" />
          <span class="ml-0.5">{{ $pgettext('Button - FilterDropdowns add', 'Lägg till') }}</span>
        </button>

        <template #popper>
          <FilterDropdownsBenchmarkPopper
            :exclude-groups="excludeGroups"
            :dropdown-settings="{ multiselect: true }"
            @selected="handleSelected"
          />
        </template>
      </VMenu>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { isEmpty, isArray } from 'lodash-es';
import { klona } from 'klona';
import { translateTerm, translateBenchmark } from 'Utils/general';
import { BENCHMARK_ICONS } from 'Utils/benchmarks';
import { wasOpenedFromBenchmark, mergeSimpleMetadata } from 'Utils/filterBox';
import FilterDropdownsBenchmarkPopper from 'Components/parts/filter-dropdowns/FilterDropdownsBenchmarkPopper';
import BadgeListItemBenchmark from 'Components/parts/badges/BadgeListItemBenchmark';
import { store } from '@/store';
import gettext from '@/gettext';

const { $pgettext } = gettext;

const props = defineProps({
  benchmarkMetadata: {
    type: Object,
    default: () => ({}),
  },
  isCard: {
    type: Boolean,
    default: false,
  },
  isTemporary: {
    type: Boolean,
    default: false,
  },
  updateDatabaseFn: {
    type: Function,
    default: () => {},
  },
  shouldOpenBadges: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:benchmarkMetadata']);

const badgeRefs = ref({});
const handleUpdateShown = ({ item, shown }) => { badgeRefs.value[item.slug] = shown; };

const selectedBenchmarks = computed({
  get: () => props.benchmarkMetadata ?? {}, // From top: simple db store value
  set: (benchmarkMetadata) => {
    if (benchmarkMetadata !== null) emit('update:benchmarkMetadata', benchmarkMetadata); // From bottom: bigger options object
  },
});

const shouldShowAddBenchmark = computed(() => {
  const bKeys = Object.keys(selectedBenchmarks.value);
  const hasSelectedAGeneralBenchmark = bKeys.includes('general');
  const hasAtLeastOneSelectedBenchmark = bKeys.length > 0;
  const hasNotSelectedAllPossibleGroups = bKeys.length < 4;
  return (!hasSelectedAGeneralBenchmark || !hasAtLeastOneSelectedBenchmark) && hasNotSelectedAllPossibleGroups;
});

const excludeGroups = computed(() => {
  const bKeys = Object.keys(selectedBenchmarks.value);
  if (bKeys.length !== 0 && !bKeys.includes('general')) return ['general', ...bKeys];
  return bKeys;
});

const badges = computed(() => {
  const objEntries = Object.entries(selectedBenchmarks.value);
  if (objEntries.length === 0 && !props.isCard && !props.isTemporary) {
    return [{
      slug: 'default',
      label: $pgettext('Badge - FilterDropdownsBenchmark default', 'Global'),
      value: [$pgettext('Badge - FilterDropdownsBenchmark default', 'Global')],
      icon: 'zmdi-globe',
    }];
  }

  return objEntries?.reduce((acc, [key, val]) => {
    let value = [];
    if (!isEmpty(val)) {
      if (!isArray(val)) val = [val];
      value = translateBenchmark(key, val);
    }

    let label = translateTerm(key);
    let icon = BENCHMARK_ICONS[key] || '';
    if (key === 'general' && val?.[0] === 'global') {
      label = $pgettext('Badge - FilterDropdownsBenchmark global', 'Global');
      icon = BENCHMARK_ICONS.global;
    } else if (key === 'general' && val?.[0] === 'company') {
      label = store.getters.customerName || $pgettext('Option - KM FilterBox Benchmarks', 'Mitt företag');
      icon = BENCHMARK_ICONS.company;
    }

    acc.push({
      slug: `benchmark__${key.toLowerCase().split(' ').join('_')}`,
      label,
      value,
      icon,
      boxRowValue: val,
      boxRowKey: key,
      boxRowType: null,
    });
    return acc;
  }, []) ?? [];
});

const handleSelected = async (options = []) => {
  // ? Update selectedBenchmarks to be completely up to date
  const newMetadata = mergeSimpleMetadata(selectedBenchmarks.value, options);
  selectedBenchmarks.value = newMetadata;
};

const isPopperDisabled = ref(false);
const handleRemove = async (removedBadge) => {
  isPopperDisabled.value = true;

  // ? Update selectedBenchmarks to be completely up to date
  let newBenchmarks = klona(selectedBenchmarks.value);
  if (newBenchmarks?.[removedBadge.boxRowKey]) {
    delete newBenchmarks[removedBadge.boxRowKey];
  }
  selectedBenchmarks.value = newBenchmarks;

  isPopperDisabled.value = false;
};

const timers = ref([]);
onMounted(() => {
  if ((props.isCard || props.isTemporary) && wasOpenedFromBenchmark(store.state.cards.modalCardOpenedFrom)) {
    Object.entries(badgeRefs.value ?? {}).forEach(([key, val]) => {
      handleUpdateShown({
        item: { slug: key },
        shown: key === `benchmark__${store.state.cards.modalCardOpenedFrom}`,
      });
    });
    timers.value.push(setTimeout(() => store.dispatch('setModalCardOpenedFrom', null), 2000));
  }
});
onUnmounted(() => {
  timers.value.forEach((timer) => clearTimeout(timer));
});

</script>
