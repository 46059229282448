<template>
  <div>
    <ul class="block-links pb-1">
      <router-link
        v-if="routeName !== 'board' && routeName !== 'overview'"
        :to="board?.is_overview
          ? { name: 'overview', params: { segmentId: board.segment } }
          : { name: 'board', params: { boardId: board.id, segmentId: board.segment } }"
      >
        <li
          v-tooltip.left="{
            distance: 4,
            container: 'body',
            autoBoundaryMaxSize: true,
            content: () => $pgettext('Tooltip — go to report button', 'Gå till rapporten')
          }"
          class="block-link btn-icon-text cursor-pointer py-1"
          data-testid="goToReportLink"
          type="button"
          tabindex="0"
        >
          <i class="zmdi zmdi-arrow-left mr-0.5" />
          {{ $gettext('Visa rapport') }}
        </li>
      </router-link>
      <li
        v-if="routeName === 'board' || routeName === 'overview'"
        v-close-popper
        v-tooltip.left="{
          distance: 4,
          container: 'body',
          autoBoundaryMaxSize: true,
          content: () => isOrganizing ? $gettext('Ändrar storlekar på cards') : $gettext('Ändra storlekar på cards'),
        }"
        class="block-link btn-icon-text cursor-pointer py-1"
        :class="{ 'active': isOrganizing }"
        data-testid="organizeButton"
        type="button"
        tabindex="0"
        :aria-disabled="isOrganizeToggling || props.disabledButtons"
        @click.prevent="organize"
        @keypress.enter="organize"
      >
        <span>
          <i
            class="zmdi mr-0.5"
            :class="{
              'zmdi-view-compact': isOrganizing,
              'zmdi-aspect-ratio-alt': !isOrganizing
            }"
          />
          {{ isOrganizing ? $gettext('Ändrar storlekar') : $gettext('Ändra storlekar') }}
        </span>
      </li>
      <li
        v-close-popper
        v-tooltip.left="{
          distance: 4,
          container: 'body',
          autoBoundaryMaxSize: true,
          content: () => $pgettext('Tooltip — clone board button', 'Kopiera denna rapport till ett annat segment')
        }"
        class="block-link btn-icon-text cursor-pointer py-1"
        data-testid="cloneReportLink"
        type="button"
        tabindex="0"
        :aria-disabled="props.disabledButtons"
        @click.prevent="clone"
        @keypress.enter="clone"
      >
        <span>
          <i class="zmdi zmdi-flip-to-front mr-0.5" />
          {{ $pgettext('Link — DropdownBoardSettings clone', 'Kopiera rapport') }}
        </span>
      </li>
      <!-- v-if="routeName === 'board' || routeName === 'overview'" -->
      <li
        v-close-popper
        v-tooltip.left="{
          distance: 4,
          container: 'body',
          autoBoundaryMaxSize: true,
          content: () => $pgettext(
            'Tooltip — name edit button',
            'Byt namn på rapporten'
          ),
        }"
        class="block-link btn-icon-text cursor-pointer py-1"
        data-testid="nameEditLink"
        type="button"
        tabindex="0"
        :aria-disabled="props.disabledButtons"
        @click.prevent="emit('edit-name')"
        @keypress.enter="emit('edit-name')"
      >
        <span>
          <i class="zmdi zmdi-edit mr-0.5" />
          {{ $pgettext('Link — DropdownBoardSettings remove', 'Ändra namn') }}
        </span>
      </li>
      <li
        v-if="isBoardOwner"
        v-close-popper
        v-tooltip.left="{
          distance: 4,
          container: 'body',
          autoBoundaryMaxSize: true,
          content: () => isOverviewBoard
            ? $pgettext(
              'Tooltip — visibility button is overview',
              'Du kan inte ändra synlighet till Privat för en rapport som används som Översikt.'
            )
            : isVisibilityPublic
              ? $pgettext(
                'Tooltip — visibility button make private',
                'Gör rapporten endast synlig för dig'
              )
              : $pgettext(
                'Tooltip — visibility button make public',
                'Gör rapporten synlig för alla med tillgång till segmentet'
              ),
        }"
        class="block-link btn-icon-text cursor-pointer py-1"
        data-testid="visibiltiyToggleLink"
        type="button"
        tabindex="0"
        :aria-disabled="isSettingAsOverview || isOverviewBoard || props.disabledButtons || !isBoardOwner"
        @click.prevent="toggleVisibility"
        @keypress.enter="toggleVisibility"
      >
        <template v-if="isVisibilityPublic">
          <span>
            <i class="zmdi zmdi-key mr-0.5" />
            {{ $pgettext('Link — DropdownBoardSettings visibility make private', 'Ändra till privat') }}
          </span>
          <!-- <br>
          <span class="ml-1 tiny-text subtle-text text-wrap">
            {{ $pgettext('Description — Private', 'Endast synlig för dig.') }}
          </span> -->
        </template>
        <template v-else>
          <span>
            <span
              class="zmdi-hc-stack"
              style="width:1em; height:1em; line-height:1;"
            >
              <i class="zmdi zmdi-key zmdi-hc-stack-1x" />
              <i
                class="zmdi zmdi-minus zmdi-hc-stack-1x"
                style="color:white; transform:rotate(45deg) scale(2) translateY(1px)"
              />
              <i
                class="zmdi zmdi-minus zmdi-hc-stack-1x"
                style="transform:rotate(45deg) scale(1.8) translate(-1.5px, 1.7px)"
              />
            </span>
            {{ $pgettext('Link — DropdownBoardSettings visibility make public', 'Ändra till publik') }}
          </span>
          <!-- <br>
          <span class="ml-1 tiny-text subtle-text text-wrap">
            {{ $pgettext('Description — Public', 'Synlig för alla med tillgång till segmentet.') }}
          </span> -->
        </template>
      </li>
      <!-- eslint-disable max-len -->
      <li
        v-close-popper
        v-tooltip.left="{
          distance: 4,
          container: 'body',
          autoBoundaryMaxSize: true,
          content: () => isOverviewBoard
            ? $pgettext(
              'Tooltip — overview toggle button active',
              'Används som Översiktsrapport och visas först av alla för detta segment'
            )
            : isVisibilityPublic
              ? $pgettext(
                'Tooltip — overview toggle button inactive public',
                'Använd som Översiktsrapport för att visa den först av alla för detta segment'
              )
              : $pgettext(
                'Tooltip — overview toggle button inactive private',
                'Översiktsrapporten är den som visas först av alla för detta segment. Kan inte använda privata rapporter som Översikt. Ändra till publik rapport först.'
              ),
        }"
        class="block-link btn-icon-text cursor-pointer py-1"
        data-testid="overviewToggleLink"
        type="button"
        tabindex="0"
        :aria-disabled="isSettingAsOverview || !isVisibilityPublic || isOverviewBoard || props.disabledButtons"
        @click.prevent="patchOverviewBoard"
        @keypress.enter="patchOverviewBoard"
      >
        <span>
          <i
            class="zmdi mr-0.5"
            :class="{
              'zmdi-spinner zmdi-hc-spin tc-color-blue': isSettingAsOverview,
              'zmdi-compass': !isSettingAsOverview,
            }"
          />
          {{ isOverviewBoard ? $gettext('Används som översikt') : $gettext('Använd som översikt') }}
        </span>
      </li>
      <li
        v-close-popper
        v-tooltip.left="{
          distance: 4,
          container: 'body',
          autoBoundaryMaxSize: true,
          content: () => isOverviewBoard
            ? $pgettext(
              'Tooltip — remove report button isOverview',
              'Kan inte ta bort rapporter som används som Översikt. Välj en annan rapport som Översikt innan du raderar denna.'
            )
            : $pgettext(
              'Tooltip — remove report button',
              'Ta bort rapporten helt och hållet'
            ),
        }"
        class="block-link btn-icon-text cursor-pointer py-1 tc-color-red"
        data-testid="removeReportLink"
        type="button"
        tabindex="0"
        :aria-disabled="!props.board?.id || isRemoving || isOverviewBoard || props.disabledButtons"
        @click.prevent="remove"
        @keypress.enter="remove"
      >
        <span>
          <i
            class="zmdi mr-0.5"
            :class="{
              'zmdi-spinner zmdi-hc-spin tc-color-blue': isRemoving,
              'zmdi-delete': !isRemoving,
            }"
          />
          {{ $pgettext('Link — DropdownBoardSettings remove', 'Radera') }}
        </span>
      </li>
      <!-- eslint-enable max-len -->

      <li><hr class="mt-0 mb-1 hr-thin block-links"></li>

      <li class="block-link-text">
        <small class="item-col subtle-text">
          <span>{{ $gettext('Ägare:') }}</span>
          <span class="bold-text">&thinsp;{{ (props.board.owner && displayName) || $gettext('Laddar…') }}</span>
        </small>
      </li>
      <li
        v-if="updatedAtPretty"
        v-tooltip.left="{
          distance: 4,
          container: 'body',
          autoBoundaryMaxSize: true,
          html: true,
          content: () => $pgettext(
            'Tooltip — Meta report dates',
            '<b>Ändrad</b> %{uAt}<br><b>Skapad</b> %{cAt}',
            { uAt: updatedAtPretty, cAt: createdAtPretty},
            { html: true }
          ),
        }"
        class="block-link-text"
      >
        <small class="item-col subtle-text">
          <span>{{ $gettext('Senaste ändring:') }}</span>
          <span class="bold-text">&thinsp;{{ updatedAtPretty || $gettext('Laddar…') }}</span>
        </small>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { klona } from 'klona';
import segmentAPI from 'API/segment';
import { update as updateBoard } from 'API/boards';
import dynamicImport from 'Utils/dynamicImport';
import { format } from 'Utils/dateHelpers';
import eventBus from 'Utils/eventBus';
import { confirm } from 'Modal/dialogBoxes';
import { store } from '@/store';
import gettext from '@/gettext';

const { $gettext, $pgettext } = gettext;

const router = useRouter();
const route = useRoute();
const routeName = computed(() => route.name);

const props = defineProps({
  board: {
    type: Object,
    required: true,
  },
  disabledButtons: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['edit-name', 'fetch-boards']);

const isSaving = ref(false);
const isRemoving = ref(false);

// eslint-disable-next-line vue/no-setup-props-destructure
const isVisibilityPublic = ref(props.board.is_public ?? true);
watch(() => props.board?.id, () => {
  isVisibilityPublic.value = props.board.is_public ?? true;
});

const isOrganizing = computed(() => store.state?.boards?.isOrganizing ?? false);
const isOrganizeToggling = ref(false);
const organize = (e) => {
  if (isOrganizeToggling.value || props.disabledButtons) return;
  isOrganizeToggling.value = true;
  store.dispatch('setBoardIsOrganizing', !isOrganizing.value);
  setTimeout(() => {
    isOrganizeToggling.value = false;
  }, 300); // Depends on scale animation duration
};

const clone = (e) => {
  if (props.disabledButtons) return;
  const CloneBoardDetails = dynamicImport(() => import(/* webpackChunkName: "CloneBoardDetails", webpackPrefetch: true */ 'Components/parts/details/CloneBoardDetails'));
  store.dispatch('openModal', {
    name: 'CloneBoardDetails',
    component: CloneBoardDetails,
    dataObject: {
      props: {
        board: props.board,
      },
    },
    callback: (newDataObject) => {
      if (routeName.value !== 'board' && routeName.value !== 'overview') {
        emit('fetch-boards');
      }
    },
  });
};

const notifyCouldntSave = (request) => store.dispatch('notify', {
  type: 'slow',
  level: 'warning',
  text: $gettext(
    'Kunde inte spara: %{msg}',
    { msg: request?.response?.body?.detail ?? request?.message ?? request },
  ),
});
const isBoardOwner = computed(() => props.board?.owner?.id === store.getters?.me?.id);
const isOverviewBoard = computed(() => props?.board?.is_overview);
const isSettingAsOverview = ref(false);
const patchOverviewBoard = async () => {
  if (isSettingAsOverview.value || !isVisibilityPublic.value || isOverviewBoard.value || props.disabledButtons) return;
  try {
    isSettingAsOverview.value = true;
    const segmentId = props.board.segment || store.getters.segment?.id;
    if (!props.board.segment || store.getters.segment?.id == null) throw new Error($pgettext('Error — No ID for overview patch', 'Saknade segment ID'));
    const segment = await segmentAPI.patch(segmentId, { overview_board_id: props.board.id });
    await store.dispatch('setSegmentBoard', segment.overview_board_id);
    emit('fetch-boards');
    store.dispatch('notify', {
      type: 'pop',
      level: 'success',
      text: $gettext(
        '%{board} används nu som Översikt',
        { board: props.board.name ?? $gettext('Den här rapporten') },
      ),
    });
  } catch (err) {
    notifyCouldntSave(err);
  } finally {
    isSettingAsOverview.value = false;
  }
};

async function saveVisibility() {
  try {
    isSaving.value = true;
    if (isVisibilityPublic.value !== props.board.is_public) {
      const board = await updateBoard(props.board.id, { isPublic: isVisibilityPublic.value });
      await store.dispatch('setBoard', { board });
      emit('fetch-boards');
    }
  } catch (error) {
    notifyCouldntSave(props.board.name ?? 'report');
  } finally {
    isSaving.value = false;
  }
}

function remove() {
  if (!props.board?.id || isRemoving.value || isOverviewBoard.value || props.disabledButtons) return;
  confirm(
    $gettext('Är du säker på att du vill ta bort rapporten?'),
    $pgettext('Confirm — Modal', 'Ta bort rapporten'),
    $pgettext('Cancel — Modal', 'Avbryt'),
    'danger',
  )
    .then((confirmed) => {
      if (!confirmed || typeof props.board?.remove !== 'function') return Promise.resolve();
      isRemoving.value = true;
      const board = klona(props.board);
      eventBus.$emit('removing-board', props.board.id);
      return board.remove()
        .then(() => {
          eventBus.$emit('removed-board', props.board.id);
          if (routeName.value === 'board' || routeName.value === 'overview') router.push({ name: 'home' }).catch((err) => {}); // Could perhaps be `name: 'overview'`
        })
        .catch((request) => {
          eventBus.$emit('removed-board', false);
          return store.dispatch('notify', {
            text: $gettext(
              'Kunde inte ta bort ”%{name}”. %{msg}',
              { name: props?.board?.name ?? $gettext('rapporten'), msg: request?.response?.body?.detail || request },
            ),
            level: 'error',
          });
        })
        .then(() => { isRemoving.value = false; });
    });
}

const toggleVisibility = (e) => {
  if (isSettingAsOverview.value || isOverviewBoard.value || props.disabledButtons || !isBoardOwner.value) return;
  isVisibilityPublic.value = !isVisibilityPublic.value;
  saveVisibility();
};

// ? View
const displayName = computed(() => {
  if (props.board.owner.first_name && props.board.owner.last_name) return `${props.board.owner.first_name} ${props.board.owner.last_name}`;
  if (props.board.owner.first_name) return props.board.owner.first_name;
  if (props.board.owner.last_name) return props.board.owner.last_name;
  if (props.board.owner.username) return props.board.owner.username;
  return props.board.owner.email;
});

const createdAtPretty = computed(() => {
  if (!props.board?.created_at) return '';
  const date = new Date(props.board.created_at);
  return format(date, "yyyy-MM-dd' 'HH:mm");
});
const updatedAtPretty = computed(() => {
  if (!props.board?.updated_at) return '';
  const date = new Date(props.board.updated_at);
  return format(date, "yyyy-MM-dd' 'HH:mm");
});

</script>
