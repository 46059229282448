<template>
  <div
    class="filter-dropdowns-columns"
    v-bind="$attrs"
  >
    <FilterDropdownsDate
      v-model:date-metadata="dateMetadata"
      class="filter-dropdowns-column date-column"
      :is-card="isCard"
      :is-temporary="isTemporary"
    />
    <FilterDropdownsFilter
      ref="FilterDropdownsFilterEl"
      v-model:filter-metadata="filterMetadata"
      class="filter-dropdowns-column filter-column"
      :is-card="isCard"
      :board-id="props.boardId"
      :is-temporary="isTemporary"
    />
    <FilterDropdownsBenchmark
      v-if="canUseBenchmarks"
      v-model:benchmark-metadata="benchmarkMetadata"
      class="filter-dropdowns-column benchmark-column"
      :is-card="isCard"
      :should-open-badges="shouldOpenBadges"
    />
    <FilterDropdownsCompare
      v-if="canUseCompare"
      v-model:compare-metadata="compareMetadata"
      class="filter-dropdowns-column compare-column"
      :is-card="isCard"
    />
  </div>
</template>

<script setup>
import { ref, computed, watch, onActivated, onDeactivated } from 'vue';
import { useVModels } from '@vueuse/core';
import {
  isEqual, isEqualWith, isArray, isEmpty, isObject, debounce, xor, flattenDeep,
} from 'lodash-es';
import { klona } from 'klona';
import boardAPI, { DEFAULT_BOARD_METADATA } from 'API/boards';
import { DEFAULT_CARD_METADATA } from 'API/cards';
import { GOAL_GRAPH_TYPES, KEY_METRIC_GRAPH_TYPES, canUseFilterBox } from 'Utils/graph';
import { store } from '@/store';
import FilterDropdownsDate from './FilterDropdownsDate';
import FilterDropdownsFilter from './FilterDropdownsFilter';
import FilterDropdownsBenchmark from './FilterDropdownsBenchmark';
import FilterDropdownsCompare from './FilterDropdownsCompare';

const props = defineProps({
  metadata: {
    type: Object,
    default: () => ({}),
  },
  boardId: Number,
  cardId: Number,
  hasChanges: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:metadata', 'update:hasChanges']);
const modalCard = computed(() => store.getters.modalCard);

const isCard = computed(() => props.cardId > 0);
const isBoard = computed(() => props.boardId > 0);
const isTemporary = computed(() => !isBoard.value && !isCard.value);
const isGoal = computed(() => GOAL_GRAPH_TYPES[store.getters.modalCard?.metadata?.graphType?.selected]);
const isModalCard = computed(() => modalCard.value?.metadata?.graphType !== undefined);
const isKeyMetricCard = computed(() => isModalCard.value
  && Object.values(KEY_METRIC_GRAPH_TYPES).includes(modalCard.value?.metadata?.graphType?.selected));

const question = ref(null);
const getQuestion = async (questionId) => store.dispatch('getQuestionById', questionId);
if (isModalCard.value) question.value = getQuestion(store.getters.modalCard?.metadata?.question);
watch(
  () => store.getters.modalCard?.metadata?.question,
  async (newVal) => { question.value = await getQuestion(newVal); },
  { immediate: true },
);

const keyMetricHasCompare = computed(() => ['bar'].includes(modalCard.value.metadata.graphType?.settings?.[KEY_METRIC_GRAPH_TYPES.ResponseRate]?.type));
const canUseCompare = computed(() => {
  if (isKeyMetricCard.value && keyMetricHasCompare.value) return true;
  if (isBoard.value || (isTemporary.value && !isModalCard.value)) return true; // ? board-filter & survey-filter
  if (isGoal.value || !isModalCard.value) return false;
  return ['QueryTable'].includes(modalCard.value.metadata?.graphType?.selected);
});
const canUseBenchmarks = computed(() => {
  if (isKeyMetricCard.value) return canUseFilterBox('allBenchmarks', '', store.getters.modalCard?.metadata?.graphType?.selected);
  if (isGoal.value) return false;
  if (isModalCard.value && question.value?.question_type) return canUseFilterBox('allBenchmarks', question.value.question_type);
  return true;
});

let originalMetadata = null; // used for resetting changes
const tempMetadata = ref(null);
const storeMetadata = computed(() => {
  if (isBoard.value) return store.getters.getBoardById(props.boardId)?.metadata ?? DEFAULT_BOARD_METADATA;
  if (isCard.value) return store.getters.modalCard?.metadata ?? DEFAULT_CARD_METADATA;
  return tempMetadata.value ?? {}; // DEFAULT_BOARD_METADATA?
});

/** removeEmptyArrays — Mutating passed object */
const removeEmptyArrays = (obj) => {
  if (!isObject(obj)) return obj;
  Object.keys(obj).forEach((key) => {
    if (isObject(obj[key])) removeEmptyArrays(obj[key]);
    if (isArray(obj[key]) && isEmpty(obj[key])) delete obj[key];
  });
  return obj;
};

/** cleanChanges — Not mutating passed object */
function cleanChanges(metadata) {
  const modifiedMetadata = klona(metadata);
  removeEmptyArrays(modifiedMetadata.filter ?? {});
  removeEmptyArrays(modifiedMetadata.benchmark ?? {});
  return modifiedMetadata;
}

function getOnlyFilter(filter) {
  if (filter == null) return {};
  const { date, ...rest } = filter; // perhaps `level` as well?
  return rest;
}

const updateCardStoreMetadata = (payload = {}) => {
  store.dispatch('setModalCardPath', ({
    path: 'metadata',
    value: {
      ...storeMetadata.value,
      ...payload,
    },
  }));
};
const updateBoardStoreMetadata = (payload = {}) => {
  const board = klona(store.getters.getBoardById(props.boardId));
  const metadata = payload;
  store.dispatch('setBoard', ({ board: { ...board, metadata } }));
};
const debouncedUpdateCardStoreMetadata = debounce(updateCardStoreMetadata, 50);
const debouncedUpdateBoardStoreMetadata = debounce(updateBoardStoreMetadata, 50);
const debounceSetMetadata = (data) => {
  if (isCard.value) debouncedUpdateCardStoreMetadata({ ...storeMetadata.value, ...data });
  else debouncedUpdateBoardStoreMetadata({ ...storeMetadata.value, ...data });
};

const dateMetadata = isTemporary.value ? ref({}) : computed({
  get: isCard.value
    ? () => (storeMetadata.value?.filter?.date ?? {})
    : () => (storeMetadata.value?.filter?.date ?? DEFAULT_BOARD_METADATA.filter.date),
  set: (newVal) => (newVal
    ? debounceSetMetadata({ filter: { ...storeMetadata.value?.filter, date: newVal } })
    : null),
});
const benchmarkMetadata = isTemporary.value ? ref(DEFAULT_BOARD_METADATA.benchmark) : computed({
  get: isCard.value
    ? () => (storeMetadata.value?.benchmark ?? DEFAULT_CARD_METADATA.benchmark)
    : () => (storeMetadata.value?.benchmark ?? DEFAULT_BOARD_METADATA.benchmark),
  set: (newVal) => (newVal
    ? debounceSetMetadata({ benchmark: newVal })
    : null),
});
const filterMetadata = isTemporary.value ? ref(getOnlyFilter({ ...DEFAULT_BOARD_METADATA.filter, ...props.metadata.filter })) : computed({
  get: isCard.value
    ? () => (getOnlyFilter(storeMetadata.value?.filter) ?? DEFAULT_CARD_METADATA.filter)
    : () => (getOnlyFilter(storeMetadata.value?.filter) ?? DEFAULT_BOARD_METADATA.filter),
  set: (newVal) => (newVal
    ? debounceSetMetadata({
      filter: {
        ...newVal,
        ...((!isCard.value // Always set `date` for board
          || (isCard.value && storeMetadata.value?.filter?.date) // Only set `date` if it exists for card.
        ) && { date: storeMetadata.value.filter.date }),
      },
    })
    : null),
});
const compareMetadata = isTemporary.value ? ref(DEFAULT_BOARD_METADATA.compare) : computed({
  get: isCard.value
    ? () => (storeMetadata.value?.compare ?? DEFAULT_CARD_METADATA.compare)
    : () => (storeMetadata.value?.compare ?? DEFAULT_BOARD_METADATA.compare),
  set: (newVal) => debounceSetMetadata({ compare: newVal }),
});

const combinedMetadata = computed(() => ({
  compare: compareMetadata.value,
  benchmark: benchmarkMetadata.value,
  filter: {
    ...filterMetadata.value,
    date: dateMetadata.value,
  },
}));

const isContextEqual = (objVal, othVal, context = '') => {
  const objContext = objVal?.[context] ?? {};
  const othContext = othVal?.[context] ?? {};
  const objKeys = Object.keys(objContext) ?? [];
  const othKeys = Object.keys(othContext) ?? [];
  const allKeys = [...new Set([...objKeys, ...othKeys])];

  if (context === 'filter') { // ? Standardize the new Migration to always have these keys including `filter.date`
    objContext.tags = objContext.tags ?? {};
    objContext.answers = objContext.answers ?? {};
  }

  return allKeys.every((key) => {
    const bothHasKey = objKeys.includes(key) && othKeys.includes(key);
    const bothContextsEmptyForKey = bothHasKey && isEmpty(objContext[key]) && isEmpty(othContext[key]);

    if (bothContextsEmptyForKey) return true;
    if (!bothHasKey) return false;

    if (key === 'date') return isEqual(objContext[key], othContext[key]);
    if (isObject(objContext[key]) && isObject(othContext[key])) {
      const othValuesFlattened = flattenDeep(Object.values(othContext[key]));
      const objValuesFlattened = flattenDeep(Object.values(objContext[key]));
      return xor(objValuesFlattened, othValuesFlattened).length === 0;
    }
    return true;
  });
};

const isEqualCustomizerChill = (objVal, othVal) => {
  if (objVal === null && isArray(othVal) && isEmpty(othVal)) return true; // ? Consider obj`{ compare: null }` & oth`{ compare: [] }` the same
  const filterEqual = othVal?.filter && objVal?.filter ? isContextEqual(objVal, othVal, 'filter') : true;
  const benchmarkEqual = othVal?.benchmark && objVal?.benchmark ? isContextEqual(objVal, othVal, 'benchmark') : true;
  const compareEqual = isEqual(othVal?.compare, objVal?.compare);
  if (!filterEqual || !benchmarkEqual || !compareEqual) return false;
  if (filterEqual && benchmarkEqual && compareEqual) return true;
  return undefined; // ? Other values are handled by default isEqual
};

const shouldSetOriginalMetadata = ref(true);
const isMounting = ref(true);
const { hasChanges } = useVModels(props, emit);
const localMetadata = computed(() => {
  if (isTemporary.value) return combinedMetadata.value;
  if (isCard.value) return props.metadata; // Should be same as `store.getters.modalCard.metadata`
  return storeMetadata.value;
});
const shouldOpenBadges = computed(
  () => (!isTemporary.value
    ? originalMetadata !== null && !isEqual(klona(localMetadata.value), originalMetadata)
    : true),
);
onActivated(async () => {
  originalMetadata = null;
  tempMetadata.value = null;
  shouldSetOriginalMetadata.value = true;
  isMounting.value = true;
});
onDeactivated(() => { hasChanges.value = false; });
watch(
  [
    localMetadata,
    () => props.boardId,
    () => store.getters.segmentId,
  ],
  ([newMeta, newBoardId, newSegmentId], [_, oldBoardId, oldSegmentId]) => {
    const newMetaVal = klona(newMeta);
    if (newBoardId && newBoardId !== oldBoardId) {
      shouldSetOriginalMetadata.value = true;
    }
    if (shouldSetOriginalMetadata.value && !isEmpty(newMetaVal)) {
      originalMetadata = cleanChanges(Object.freeze(newMetaVal));
      tempMetadata.value = originalMetadata ?? {};
      shouldSetOriginalMetadata.value = false;
    }
    if (!isTemporary.value && !isMounting.value) {
      const changingSegment = newSegmentId !== oldSegmentId;
      if (
        !changingSegment
        && !isEmpty(newMetaVal)
        && !isEqualWith(newMetaVal, originalMetadata, isEqualCustomizerChill)
      ) {
        hasChanges.value = true;
      } else {
        hasChanges.value = false;
      }
    }
    if (isMounting.value && !isEmpty(newMetaVal)) isMounting.value = false;
  },
  { immediate: false, deep: true },
);

if (isTemporary.value) {
  watch(
    () => combinedMetadata.value,
    (newVal, oldVal) => {
      if (!isEqual(newVal, oldVal)) {
        tempMetadata.value = { ...tempMetadata.value, ...newVal };
        emit('update:metadata', tempMetadata.value);
      }
    },
    { deep: true },
  );
}

async function saveCardChanges(cardId) {
  // Save all changes made to provided cardId
  console.error('saveCardChanges() is not used yet. Needs implementation if you want to use it', cardId); // eslint-disable-line no-console
  // const card = await cardAPI.updateMetadata(cardId, storeMetadata.value);
  // originalMetadata = Object.freeze(klona(card.metadata));
}

async function saveBoardChanges(boardId) {
  const metadata = cleanChanges(storeMetadata.value);
  try {
    store.dispatch('setFilterFetchingState', true);
    hasChanges.value = false;
    const board = await boardAPI.updateMetadata(boardId, metadata);
    store.dispatch('setBoard', ({ board }));
    originalMetadata = Object.freeze(klona(board.metadata));
    store.dispatch('setFilterFetchingState', false);
    return Promise.resolve(board);
  } catch (error) {
    hasChanges.value = true;
    store.dispatch('setFilterFetchingState', false);
    return Promise.reject(error);
  }
}

function saveFilterChanges() {
  if (isCard.value) saveCardChanges(props.cardId);
  else if (isBoard.value) saveBoardChanges(props.boardId);
  else throw new Error('Can’t save without boardId or cardId');
}
function resetFilterChanges() {
  if (isCard.value) updateCardStoreMetadata(originalMetadata);
  if (isBoard.value) updateBoardStoreMetadata(originalMetadata);
  else tempMetadata.value = originalMetadata;
  this.$refs?.FilterDropdownsFilterEl?.clearBadgeRefs?.();
}

defineExpose({
  saveFilterChanges,
  resetFilterChanges,
});
</script>
