<template>
  <section ref="BoardSectionEl">
    <div
      class="board-section-container"
      :class="{
        'is-draggable': !props.disableDragOuter,
        'is-editing': isEditing,
        'is-not-edited': !(section.name || section.description || section.color),
        'is-empty': showAsEmpty,
        [themeColorClass]: true
      }"
    >
      <div
        v-tc-loader-spinner="isLoading"
        class="board-section-header"
      >
        <div
          v-show="!props.disableDragOuter"
          class="draghandle-wrapper"
        >
          <div class="draghandle js-board-section-draghandle" />
        </div>
        <BoardSectionActions
          v-if="!props.disableDragOuter || props.disableDragOuter"
          ref="boardCardActionsEl"
          v-model:is-editing="isEditing"
          :board-id="props.board.id"
          :section-id="props.section.id"
          :show-remove="!disableDragOuter"
          :is-empty="showAsEmpty"
          @remove-section="removeSection"
          @save-section="saveSection"
          @add:cards="addCardsToInnerGrid"
        />
        <div
          v-if="isEditing || section.name || section.description"
          class="board-section-hgroup"
        >
          <h4
            v-if="!isEditing && section.name"
            class="h2 mb-0 text-wrap"
          >
            {{ section.name }}
          </h4>
          <input
            v-if="isEditing"
            v-model="editableSection.name"
            class="form-control h2 mb-0 py-0"
            type="text"
            maxlength="255"
            :placeholder="$pgettext('Placeholder — editableSection name', 'Sektionens namn')"
            @keyup.enter="saveSection(false)"
          >
          <h5
            v-if="!isEditing && section.description"
            class="mb-0 text-wrap-paragraphable"
          >
            {{ section.description }}
          </h5>
          <textarea
            v-if="isEditing"
            v-model="editableSection.description"
            v-tc-autoresize
            class="form-control py-0"
            :placeholder="$pgettext('Placeholder — editableSection description', 'Sektionens beskrivning')"
            @keyup.ctrl.enter="saveSection(false)"
          />
        </div>
        <div
          v-if="isEditing"
          class="board-section-colorpicker"
        >
          <ColorPicker
            v-model="editableSection.color"
          />
        </div>
      </div>
      <div class="board-section-content-wrapper">
        <div
          ref="sectionContentEl"
          class="board-section-content"
          :class="{ 'is-empty': showAsEmpty }"
          :data-section-id="section.id"
          :data-section-order="section.order"
          :data-empty-drag-text="$pgettext('Empty Section — Existing', 'Släpp kortet här')"
          :data-empty-text="cardsInBoard.length
            ? $pgettext(
              'Empty Section — Existing cards',
              'Denna sektion är tom, dra in ett kort eller klicka på ”+ Lägg till Card” för att lägga till det här'
            ) : $pgettext(
              'Empty Section — No cards',
              'Denna sektion är tom, klicka på ”+ Lägg till Card” för att lägga till det här'
            )"
        >
          <BoardCard
            v-for="cardId in cardInitialIds"
            :key="cardId"
            :disable-drag-outer="disableDragOuter"
            class="board-card"
            :board="board"
            :card-id="cardId"
            @card-loaded="cardsLoaded.add(cardId)"
            @card-refresh-layout="refreshInnerGridLayout"
          />
          <BoardSectionEmpty
            v-if="showAsEmpty && (!props.disableDragOuter || props.disableDragOuter) && !isPrintViewRoute"
            :has-existing-cards="cardsInBoard.length > 0"
            :board-id="props.board.id"
            :section-id="props.section.id"
            @add:cards="addCardsToInnerGrid"
          />
          <!-- @click="showAsEmpty ? openAddCardDropdown() : null" -->
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import {
  ref, watch, computed, onMounted, onBeforeUnmount, nextTick, reactive, markRaw,
} from 'vue';
import { klona } from 'klona';
import { throttle, isEqual } from 'lodash-es';
import { useRoute } from 'vue-router';
import { useResizeObserver } from '@vueuse/core';
import eventBus from 'Utils/eventBus';
import { striphtml } from 'Utils/general';
import { useMuuriCards } from 'Composables/useMuuri';
import ColorPicker from 'Components/parts/widgets/ColorPicker';
import Confirm from 'Components/modal/Confirm';
import BoardSectionActions from 'Components/parts/board/BoardSectionActions';
import BoardSectionEmpty from 'Components/parts/board/BoardSectionEmpty';
import BoardCard from 'Components/parts/board/BoardCard';
import gettext from '@/gettext';
import { store } from '@/store';

const { $gettext, $pgettext } = gettext;

const props = defineProps({
  board: Object,
  section: Object,
  renderCardsPermission: {
    type: Boolean,
    default: false,
  },
  dragContainerEl: null, // Template ref, HTML element
  outerGrid: null, // Muuri instance
  innerDragSort: Function, // () => Muuri innerGrid instances
  hasLoadedAllSections: {
    type: Boolean,
    default: false,
  },
  disableDragOuter: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['card-grid', 'cards-loaded', 'section-fully-loaded', 'section-refresh-layout', 'remove-section']);

// ! Muuri uses the DOM for handling, so we DO NOT have a synced vDOM and DOM.
// ! We tried a lot to achieve a synced vDOM and Muuri handling, but nothing worked.
// ! All BoardCard operations need to use another way to secure that it knows which BoardSection it is in.
//   (You could use the native DOM as the source of truth for the cards)

const isPrintViewRoute = computed(() => useRoute()?.name?.indexOf('print-') > -1);
const skipMuuriAnimation = ref(false);
const refreshLayoutTimeoutId = ref(null);
const boardCardActionsEl = ref(null);
const sectionContentEl = ref(null);
const innerGrid = ref(null);
const cardsLoaded = ref(new Set());
const cardsStore = computed(() => klona(store.getters.getCardsBySectionId(props.section.id)) || []);
const cardInitialIds = ref(props.section?.cards?.map((card) => card.id) || []);
const cardsInBoard = computed(() => klona(store.getters.getCardsByBoardId(props.board.id)) || []);
const innerGridItems = computed(() => innerGrid.value?._items?.filter((item) => item?._element?.dataset?.emptyPlaceholder !== '') || []);
const showAsEmpty = ref((innerGridItems.value.length === 0 && cardsStore.value.length === 0));

const batchAmount = 5;
const hasFirstCardBatchRan = ref(false);
const inactiveInnerGridItems = computed(() => innerGridItems.value.filter((item) => !item.isActive()));
const batchedCards = computed(() => inactiveInnerGridItems.value.reduce((acc, el, index) => {
  const batchIndex = Math.floor(index / batchAmount);
  if (!acc[batchIndex]) acc[batchIndex] = [];
  acc[batchIndex].push(el);
  return acc;
}, []));

const readyForNextBatch = computed(() => cardsLoaded.value.size % batchAmount === 0);

const showReadyCardBatch = (items) => {
  if (!items?.length) return;
  const cardIds = items.map((item) => Number(item._element.dataset.cardId));

  innerGrid.value?.show(items, { instant: true,
    onFinish: () => {
      // Fixes https://app.shortcut.com/trustcruit/story/17772/bug-report-percentage-not-showing-fully-on-bar-graph
      setTimeout(() => {
        store.dispatch('setCardShown', cardIds);
      }, 1);
    } });
};
watch(() => cardsLoaded.value.size, (newVal) => {
  if (newVal === 0 || !inactiveInnerGridItems.value.length) return;
  if (readyForNextBatch.value) { setTimeout(() => { showReadyCardBatch(batchedCards.value[0]); }, 200); }
});
watch(() => [props.renderCardsPermission, inactiveInnerGridItems.value], ([newVal, newInactive]) => {
  if (hasFirstCardBatchRan.value) return;
  if (newVal && newInactive?.length) {
    hasFirstCardBatchRan.value = true;
    showReadyCardBatch(batchedCards.value[0]);
  }
}, { immediate: true });

watch(() => innerGridItems.value, (newVal) => {
  if (newVal.length === 0 && cardsStore.value.length === 0) showAsEmpty.value = true;
  if (newVal.length > 0 || cardsStore.value.length > 0) showAsEmpty.value = false;
});
// ? The vDOM is not synced with the DOM. Use together with eventBus instead
const refreshInnerGridLayout = throttle(() => {
  innerGrid.value?.refreshItems().layout(skipMuuriAnimation.value);
  emit('section-refresh-layout'); // emit so section grid can be updated
}, 10, { trailing: true, leading: false });

const setupInnerGrid = () => {
  const { dragContainerEl, innerDragSort, outerGrid } = props;
  innerGrid.value = useMuuriCards(
    sectionContentEl.value,
    {
      dragContainerEl,
      outerGrid,
      boardId: props.board?.id ?? null,
      // area: props.section?.id ?? null,
      dragSort: innerDragSort,
      dragEnabled: !props.disableDragOuter || props.disableDragOuter,
    },
  );
  if (innerGrid.value) {
    emit('card-grid', innerGrid.value);
    innerGrid.value.hide(innerGridItems.value, { instant: true });
  }

  watch(() => cardsLoaded.value.size, (newVal) => {
    if (newVal <= cardInitialIds.value.length) refreshInnerGridLayout();
    if (newVal >= cardInitialIds.value.length) {
      setTimeout(() => { emit('section-fully-loaded', newVal); }, 300);
    }
  });
};

// function openAddCardDropdown() {
//   if (boardCardActionsEl?.value?.showAddCardDropdown) boardCardActionsEl.value.showAddCardDropdown();
// }

function useEditSection(section) {
  const isLoading = ref(false);
  const isEditing = ref(false);
  const editableSection = reactive({
    name: section?.name ?? '',
    description: section?.description ?? '',
    color: section?.color ?? null,
  });

  const themeColorClass = computed(() => {
    if (editableSection?.color) return `theme--${editableSection.color}`;
    if (props.section?.color && editableSection.color !== '') return `theme--${props.section.color}`;
    return '';
  });

  const toggleEditing = (to) => {
    if (to !== undefined) isEditing.value = to;
    else isEditing.value = !isEditing.value;
  };
  watch(isEditing, async () => {
    editableSection.name = section?.name ?? '';
    editableSection.description = section?.description ?? '';
    editableSection.color = section?.color ?? '';
    await nextTick();
    emit('section-refresh-layout');
  });

  const removeSection = async () => {
    if (!section.id) return;

    store.dispatch('openModal', {
      name: 'ConfirmDialog',
      component: Confirm,
      position: 'center',
      dataObject: {
        props: {
          title: $gettext('Är du säker på att du vill ta bort sektionen%{sectionName} och alla dess kort?', {
            sectionName: section.name ? ` "${striphtml(section.name)}"` : '',
          }, { html: true }), // ! Potentially unsafe
        },
        on: {
          dismiss: (confirmed) => {
            if (!confirmed) {
              store.dispatch('closeModal'); // closing the confirm only
              return Promise.resolve();
            }
            store.dispatch('closeModal'); // closing the confirm
            return store.dispatch('removeSection', { sectionId: section.id })
              .then((removedSectionId) => emit('remove-section', removedSectionId))
              .catch((err) => store.dispatch('notify', {
                type: 'slow',
                level: 'error',
                text: $gettext('Kunde inte ta bort sektionen. %{msg}', { msg: err.response.body.detail }),
              }));
          },
        },
      },
    });
  };

  const saveSection = async (toggleEditingTo = false) => {
    const { name, description, color } = section;
    if (isEqual(editableSection, { name, description, color })) {
      toggleEditing(false);
      return;
    }

    try {
      isLoading.value = true;
      section.setInfo(markRaw(editableSection));
      await section.save(false);
      if (!toggleEditingTo) toggleEditing(toggleEditingTo);
    } catch (err) {
      store.dispatch('notify', {
        type: 'slow',
        level: 'error',
        text: $gettext('Kunde inte spara sektionen. %{msg}', { msg: err.response.body.detail }),
      });
    } finally {
      isLoading.value = false;
    }
  };

  // watch(() => editableSection.color, (newVal, oldVal) => (newVal !== oldVal ? saveSection(true) : ''));

  return { isLoading, isEditing, editableSection, toggleEditing, removeSection, saveSection, themeColorClass };
}
const { isLoading, isEditing, editableSection, removeSection, saveSection, themeColorClass } = useEditSection(props.section); // eslint-disable-line max-len

const addCardsToInnerGrid = async (cards) => {
  const newCardIds = cards.map((newCard) => newCard.id);
  cardInitialIds.value = [...cardInitialIds.value, ...newCardIds];

  nextTick(() => {
    const cardEls = newCardIds.reduce((acc, cardId) => {
      const cardEl = document.querySelector(`[data-card-id="${cardId}"]`);
      if (cardEl) acc.push(cardEl);
      return acc;
    }, []);
    if (cardEls && innerGrid.value) innerGrid.value.add(cardEls);
    emit('section-fully-loaded', cardInitialIds.value.length);
  });
};

const removeCardFromTemplate = (cardId) => {
  const cardEl = document.querySelector(`[data-card-id="${cardId}"]`);
  if (cardEl && innerGrid.value) innerGrid.value.remove(cardEl);
  cardInitialIds.value = cardInitialIds.value.filter((id) => id !== cardId);
  nextTick(refreshInnerGridLayout);
  emit('section-fully-loaded', cardInitialIds.value.length);
};

const eventBusCardRefreshLayout = (ctx) => {
  if (ctx.sectionId === props.section.id) { // ? This is how we can assure the section is the same as the visual one.
    refreshInnerGridLayout();
    if (ctx.colClassUpdated) { // ? This is because of a weird update race condish bug
      setTimeout(() => {
        innerGrid.value?.refreshItems().layout(true);
        emit('section-refresh-layout');
      }, 100);
    }
  }
};
const eventBusRemoveCard = (card) => {
  if (card.section === props.section.id) {
    removeCardFromTemplate(card.id);
  }
};
const eventBusAddCard = (card) => {
  if (card.section === props.section.id) {
    addCardsToInnerGrid([card]);
  }
};

const BoardSectionEl = ref(null);
const stopObserver = ref(() => {});
const handleResize = throttle(() => emit('section-refresh-layout'), 300);

onMounted(() => {
  ({ stop: stopObserver.value } = useResizeObserver(BoardSectionEl.value, handleResize));

  skipMuuriAnimation.value = isPrintViewRoute.value;
  // ? Set cardInitialIds from store only first time, then Muuri takes over, and we hook into Muuri to update our store & DB
  cardInitialIds.value = cardsStore.value.map((card) => card.id) || [];

  // ? We have to use eventBus to communicate between BoardSection & BoardCard.
  // ? as it will not always be the same visually if a card has been dragged between sections.
  eventBus.$on('card-refresh-layout', eventBusCardRefreshLayout);
  eventBus.$on('remove-card', eventBusRemoveCard);
  eventBus.$on('add-card', eventBusAddCard);

  nextTick(setupInnerGrid);

  refreshLayoutTimeoutId.value = setTimeout(() => {
    refreshInnerGridLayout();
  }, 5000);
});
onBeforeUnmount(() => {
  stopObserver.value();
  clearTimeout(refreshLayoutTimeoutId.value);
  eventBus.$off('card-refresh-layout', eventBusCardRefreshLayout);
  eventBus.$off('remove-card', eventBusRemoveCard);
  eventBus.$off('add-card', eventBusAddCard);

  innerGrid.value?.destroy();
  store.dispatch('clearCardShown');
});
</script>
