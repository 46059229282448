<template>
  <div
    v-if="render"
    class="tc-performance-score-indicator-container"
  >
    <div
      class="tc-performance-score-indicator-body"
      :aria-description="
        $pgettext('Aria-desc — Perf Indicator bar', 'TScore: %{tScore}, %{tooltip}', { tScore, tooltip })
      "
      tabindex="0"
      @focus="mouseOver"
      @blur="mouseLeave"
      @keydown.enter="mouseOver"
      @keydown.esc="mouseLeave"
      @mouseover="mouseOver"
      @touchstart="mouseOver"
      @mouseleave="mouseLeave"
      @touchend="mouseLeave"
    >
      <div
        v-tooltip="{
          autoHide: false,
          triggers: [],
          shown: isOpen,
          popperClass:'no-pointer-events',
          content: tooltip,
          container: false,
          autoBoundaryMaxSize: true,
          placement: tooltipPlacement,
        }"
        class="tc-performance-score-indicator-bar"
        :class="barClass"
        :style="barWidth"
      >
        <div
          v-show="headShow"
          class="tc-performance-score-indicator-head"
          :class="barColor"
        />
      </div>
    </div>
    <div
      v-if="showCompare"
      class="tc-performance-score-relational-meter"
    >
      <span class="tc-fraction-lineicon">{{ comparableOption?.[0]?.label || 'Global' }}<i
        title="comparableOption"
        class="zmdi zmdi-hc-fw"
        :class="`${comparableOption?.[0]?.icon ?? 'zmdi-globe'}`"
      /></span>
    </div>
  </div>
</template>

<script>
import { isNumber, isError } from 'lodash-es';
import { mapGetters } from 'vuex';
import { striphtml } from 'Utils/general';

export default {
  name: 'PerformanceScoreIndicator',
  props: {
    score: Number,
    comment: {
      type: String,
      default: '',
    },
    isOnlyInternalBenchmark: {
      type: Boolean,
      default: false,
    },
    showCompare: Boolean,
    comparableOption: {
      type: Array,
      default: () => [{ label: 'Global', value: 'global', icon: 'zmdi-globe' }],
    },
    usecase: {
      type: String,
      default: 'performance',
      validator: (val) => ['performance', 'swot'].includes(val),
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    ...mapGetters(['customerName']),
    render() {
      if (
        this.score !== undefined
        && isNumber(this.tScore)
        && !Number.isNaN(this.tScore)
        && this.tScore >= 0
        && this.tScore <= 100
      ) return true;
      return false;
    },
    tScore() {
      return this?.score ?? 50; // calculation done at server now.
    },
    tooltip() {
      if (this.noData) return this.comment;
      const companyName = striphtml(this.possessiveCompanyName);
      const useBenchmarkLabel = this.comparableOption?.[0]?.value === 'global';

      let benchmarkedAgainst = companyName;
      let benchmarkedAverage = this.$pgettext('Tooltip — Perf Indicator average indefinite', 'snitt');
      if (useBenchmarkLabel) {
        benchmarkedAgainst = this.comparableOption?.[0]?.label === 'Benchmark'
          ? this.$pgettext('Tooltip — Perf Indicator benchmarkLabel custom', 'benchmark')
          : this.$pgettext('Tooltip — Perf Indicator benchmarkLabel regular', 'globala');
        benchmarkedAverage = this.$pgettext('Tooltip — Perf Indicator average definite', 'snittet');
      }

      let indicateePossessive = this.$pgettext('Tooltip — Perf Indicator indicatee Possessive', 'detta segmentets');
      let indicateeDefinitive = this.$pgettext('Tooltip — Perf Indicator indicatee Definite', 'detta segment');
      if (this.usecase === 'swot') {
        indicateePossessive = this.$pgettext('Tooltip — Perf Indicator indicatee Possessive', 'denna frågans');
        indicateeDefinitive = this.$pgettext('Tooltip — Perf Indicator indicatee Definite', 'denna fråga');
      }

      if (this.score >= 75) {
        return this.$pgettext('Tooltip-Performance indicator the best', 'Över %{benchmarkedAgainst} %{benchmarkedAverage}, %{indicateePossessive} resultat är bland de bästa', { benchmarkedAgainst, benchmarkedAverage, indicateePossessive }, true);
      }
      if (this.score > 55) {
        return this.$pgettext('Tooltip-Performance indicator reach top', 'Över %{benchmarkedAgainst} %{benchmarkedAverage}, fortsätt förbättra %{indicateeDefinitive} för att nå toppen', { benchmarkedAgainst, benchmarkedAverage, indicateeDefinitive }, true);
      }
      if (this.score <= 25) {
        return this.$pgettext('Tooltip-Performance indicator needs action', 'Under %{benchmarkedAgainst} %{benchmarkedAverage}, %{indicateeDefinitive} behöver åtgärdas', { benchmarkedAgainst, benchmarkedAverage, indicateeDefinitive }, true);
      }
      if (this.score < 45) {
        return this.$pgettext('Tooltip-Performance indicator needs action', 'Under %{benchmarkedAgainst} %{benchmarkedAverage}, håll koll på %{indicateeDefinitive}', { benchmarkedAgainst, benchmarkedAverage, indicateeDefinitive }, true);
      }
      return this.$pgettext('Tooltip-Performance indicator needs action', 'Nära %{benchmarkedAgainst} %{benchmarkedAverage}', { benchmarkedAgainst, benchmarkedAverage }, true);
    },
    possessiveCompanyName() {
      const name = !isError(this.customerName) ? this.customerName : this.$gettext('Ert företag');
      return name?.substring(name.length - 1) === 's'
        ? `${name}’`
        : `${name}’s`;
    },
    trafficLightColor() {
      if (this.tScore > 55) return 'green';
      if (this.tScore < 45) return 'red';
      return 'orange';
    },
    directionRight() {
      return this.tScore >= 50;
    },
    tooltipPlacement() {
      return this.directionRight ? 'right' : 'left';
    },
    barColor() {
      return `tc-bg-${this.trafficLightColor}${this.isOnlyInternalBenchmark ? '-striped' : ''}`;
    },
    barDirection() {
      return this.directionRight ? 'tc-performance-score-positive' : 'tc-performance-score-negative';
    },
    barClass() {
      return `${this.barColor} ${this.barDirection}`;
    },
    barWidth() {
      let width = Math.abs(this.tScore - 50) * 2;
      return `width: ${width}%`;
    },
    headShow() {
      return this.tScore === 50;
    },
    noData() {
      if (this.comment === '') return false;
      return true;
    },
  },
  methods: {
    mouseOver() {
      this.isOpen = true;
    },
    mouseLeave() {
      this.isOpen = false;
    },
  },
};
</script>
